
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { Trie } from '@/store/app.utils';
import FriendlyMonsterDetail from './FriendlyMonsterDetail.vue';
import PageBreadcrumbs from '@/components/pages/PageBreadcrumbs.vue';
import ModalOverlay from '@/components/pages/ModalOverlay.vue';
import Infomation from '@/components/loading/Infomation.vue';
import { imgData } from '@/store/app.config';
import { gsReferenceFriendly } from '@/firebase/storage';
import SortUi from './SortUi.vue';
import Indicator from '@/components/loading/Indicator.vue';
import { AppData } from '@/store/app.data';

export default defineComponent({
  components: { FriendlyMonsterDetail, PageBreadcrumbs, ModalOverlay, Infomation, SortUi, Indicator },
    name:"FriendlyMonster",
    setup() {
      const result = ref<any[]>([]);
      const response = ref<any>();
      const compression = ref<any>();
      const keepList = ref<any>([]);
      const showData = ref<any>([]);
      const userData = ref("");
      const sortUiData = AppData.sortUi;
      const button = ref<boolean[][]>(sortUiData.map(data => Array(data.contents.length).fill(false)));
      let blist = ref<boolean[]>([]);
      let nowLoad = ref(true);
      const wordList = ref<string[]>([]);
      
      const attribute = ["メラ", "ギラ", "ヒャド", "バギ", "デイン", "ジバリア", "イオ", "ドルマ"];
      const attribute_pair = ["斬体", "呪文", "ブレス", "耐属性", "成功率", "ターン回復"];
      const system = ["スライム", "けもの", "ドラゴン", "虫", "鳥", "植物", "物質", "マシン", "ゾンビ", "悪魔", "エレメント", "怪人", "水", "？？？？"];
      const system_pair = ["撃系統", "耐系統"];
      const stateChange = ["毒", "麻痺", "眠り", "混乱", "封印", "幻惑", "呪い", "魅了", "怯え", "休み", "踊り"];
      const stateChange_pair = ["耐変化", "成功率"];
      const stateAbnormal = ["守備減", "攻撃減", "すばやさ減", "全状態異常"];
      const stateAbnormal_pair = ["耐異常", "成功率"];
      const probability = ["会心", "みかわし", "暴走"];
      const probability_pair = ["確率"];
      const special = ["即死", "吸収"];
      const special_pair = ["耐特殊"];
      const recovery = ["スキル", "呪文HP", "とくぎ"];
      const recovery_pair = ["回復"];

      fetchData();
      getImgApi();
      
      const monsterSearch = (word: string, i: number, j: number) => {
        button.value[i][j] = !button.value[i][j];
        if (wordList.value.includes(word)) {
          keepList.value.splice(wordList.value.indexOf(word), 1);
          wordList.value.splice(wordList.value.indexOf(word), 1);
        } else {
          wordList.value.push(word);
        }
        result.value = attribute.includes(word) || system.includes(word) || 
                       stateChange.includes(word) || stateAbnormal.includes(word) ||
                       probability.includes(word) || special.includes(word) ||
                       recovery.includes(word)
                       //trieによるcellDataでの振り分け
                       ? attriData(response.value.data) : 
                       //columnによる振り分け
                       response.value.data.filter((data:any) => !(data[word] === undefined) && !(data[word] === ""))
                                          .map((data:any) => data);
        
        if (button.value[i][j]) keepList.value.push(result.value);
        
        function attriData(datas:any[]) {
          const trie = new Trie();
          let list = ["斬体", "呪文", "ブレス", "全属性", "耐属性", "撃系統", "耐系統",
                        "耐攻撃",	"耐変化",	"耐異常",	"耐特殊",	"確率",	"成功率",	"回復",	"ターン回復"]
            
            datas.forEach((result:any) => {
              list.forEach(word => {
              if(result[word] !== "") {
                result[word].split("、").forEach((data: any) => {
                  trie.addValue(data, result);
                  })
                }
              })
            })
          return trie.wide(word);//cellValueしか取得できない,columnで仕分けできない
        }
        
        let temp: Record<string, string>[] = [];
        temp = keepList.value.flat(1);
        let dic: any = {};
        //keeplist辞書化
        temp.forEach(data => {
          dic[JSON.stringify(data)] = (dic[JSON.stringify(data)] || 0) + 1;
        })
        //keeplist内のかぶり抽出
        result.value = Object.keys(dic)
                       .filter((key) => dic[key] === wordList.value.length)
                       .map(data => JSON.parse(data));
        //url挿入
        result.value.forEach(value => {
          for(const data of imgList){
            if (value["No"] === data.num) value["url"] = data.url;
          }
        })
        
      }
      
      const imgList: imgData[] = [];
      async function getImgApi() {
        try {
          const { items } = await gsReferenceFriendly.listAll();
          const promise = items.map((item: any) => item.getDownloadURL());
          const imgArray = await Promise.all(promise)
                //.catch(error => console.log("Error fetching all download URLs: ", error));
          for (let i = 0; i < imgArray.length; i++) {
            const num = imgArray[i].split("?")[0].split("F")[1].split(".")[0];
            imgList.push({"num": Number(num), "url": imgArray[i]});
          }
          //console.log("strageOk",imgList); 
          //store.dispatch(DispatchType.SET_IMG, imgList);
        } catch(error) {
          console.log(error);
        } 
      }

      async function fetchData() {
        response.value = await axios.get(AppData.friendlyData);
        compression.value = response.value.data.map((data: any) =>
          Object.fromEntries(
            Object.entries(data).filter((entry) => entry[1] !== "")
          )
        );
        nowLoad.value = false;
      }
      const openModal = (index: number) => {
        blist.value[index] = !blist.value[index];
      }
      function wordSplit(db: string, user: string) {
        if(!db.includes(user)) return
        return db.split("、").length === 1 
               ? db : db.split("、").filter(w => w.includes(user))[0];
      }
      return { 
               result, userData, showData, button, wordList,
               monsterSearch, sortUiData, blist, openModal, nowLoad, 
               stateChange, wordSplit,
               system, system_pair, attribute, attribute_pair, 
               stateChange_pair, stateAbnormal, stateAbnormal_pair,
               probability, probability_pair, special, special_pair,
               recovery, recovery_pair,
              }
    },
})
