import store from "@/store";
import { DispatchType } from "@/store/app.config";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Top from "@/views/Top.vue";
import { AppData } from "@/store/app.data"; 


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Top",
    component: Top,
    meta: {
      title: "Topページ",
      description: "Topページ",
    },
  },
  {
    path: "/News",
    name: "News",
    meta: {
      title: "newsページ",
      description: "newsページの説明",
    },
    component: () => import(/* webpackChunkName: "News" */ "../components/pages/NewsArticle.vue"),
  },
  // {
  //   path: "/HeartRoad/:id",
  //   name: "HeartRoad",
  //   meta: {
  //     title: "こころ道" ,
  //     description: "こころ道ページ",
  //   },
  //   component: () => import(/* webpackChunkName: "HeartRoad" */ "../components/pages/HeartRoad.vue"),
  //   props: true,
  //   children: [
  //     {
  //       path: "HeartResult",
  //       name: "HeartResult",
  //       meta: {
  //         title: "条件一致こころ一覧",
  //         description: "条件一致こころ一覧ページ",
  //       },
  //       component: () => import(/* webpackChunkName: "HeartResult" */ "../components/pages/HeartResult.vue"),
  //       props: true,
  //     },
      
  //   ],
  // },
  {
    path: "/RHeartRoadTest/:id",
    name: "RHeartRoadTest",
    meta: {
      title: "こころ道" ,
      description: "こころ道ページ",
    },
    component: () => import(/* webpackChunkName: "HeartRoadTest" */ "../components/pages/RHeartRoadTest.vue"),
    props: true,
    children: [
      {
        path: "HeartResult",
        name: "HeartResult",
        meta: {
          title: "条件一致こころ一覧",
          description: "条件一致こころ一覧ページ",
        },
        component: () => import(/* webpackChunkName: "HeartResult" */ "../components/pages/HeartResult.vue"),
        props: true,
      },
      
    ],
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    meta: {
      title: "プライバシーポリシー",
      description: "プライバシーポリシー",
    },
    component: () => import(/* webpackChunkName: "header" */ "../components/header/privacyPolicy.vue"),
  },
  {
    path: "/GatherTools",
    name: "GatherTools",
    meta: {
      title: "ツール：麻雀＆プレイコイン",
      description: "ツール：麻雀＆プレイコイン",
    },
    component: () => import(/* webpackChunkName: "tool" */ "../components/header/GatherTools.vue"),
  },
  {
    path: "/playcoinmap",
    name: "PlayCoinMap",
    meta: {
      title: "プレイコインセーブマップ",
      description: "プレイコインセーブマップ",
    },
    component: () => import(/* webpackChunkName: "tool" */ "../components/tools/PlayCoinMap.vue"),
  },
  {
    path: "/Inquiry",
    name: "Inquiry",
    meta: {
      title: "お問い合わせ",
      description: "お問い合わせ",
    },
    component: () => import(/* webpackChunkName: "header" */ "../components/header/Inquiry.vue"),
  },
  {
    path: "/search",
    name: "Search",
    meta: {
      title: "searchページ",
      description: "searchページ",
    },
    component: () => import(/* webpackChunkName: "search" */ "../views/SearchResult.vue"),
  },
  {
    path: "/DetailOfHeart",
    name: "DetailOfHeart",
    meta: {
      title: "こころの詳細",
      description: "こころの詳細",
    },
    component: () => import(/* webpackChunkName: "DetailOfHeart" */ "../components/pages/DetailOfHeart.vue"),
  },
  {
    path: "/HeartsRanking",
    name: "HeartsRanking",
    meta: {
      title: "こころの性能ランキング",
      description: "こころの性能ランキング",
    },
    component: () => import(/* webpackChunkName: "HeartsRanking" */ "../views/HeartsRanking.vue"),
  },
  {
    path: "/HeartsSearch",
    name: "HeartsSearch",
    meta: {
      title: "こころの検索",
      description: "こころの検索",
    },
    component: () => import(/* webpackChunkName: "HeartsSearch" */ "../views/HeartsSearch.vue"),
  },
  {
    path: "/NameSearch",
    name: "NameSearch",
    meta: {
      title: "名前で検索",
      description: "名前で検索",
    },
    component: () => import(/* webpackChunkName: "NameSearch" */ "../views/NameSearch.vue"),
  },
  {
    path: "/FriendlyMonster",
    name: "FriendlyMonster",
    meta: {
      title: "仲間モンスターの検索",
      description: "仲間モンスターの検索",
    },
    component: () => import(/* webpackChunkName: "FriendlyMonster" */ "../views/FriendlyMonster.vue"),
  },
  {
    path: "/FriendlyMonsterResist",
    name: "FriendlyMonsterResist",
    meta: {
      title: "仲間モンスターの耐性",
      description: "仲間モンスターの耐性",
    },
    component: () => import(/* webpackChunkName: "FriendlyMonsterResist" */ "../views/FriendlyMonsterResist.vue"),
  },
  {
    path: "/:pathMatch(.*)*",//"/:catchAll(.*)"//:pathMatch(.*)*
    name: "NotFound",
    component: () => import(/* webpackChunkName: "top" */"../views/Top.vue"),
    meta: {
      title: "NotFoundページ",
      description: "NotFoundページ",
    },
  },
];

export const menuRoutes = routes.slice(0, routes.length - 1);

export const Page = {
  TOP: "Top",
  ABOUT: "About",
  AUTHOR: "Author",
  PRODUCTIONNOTE: "ProductionNote",
  FAQ: "Faq",
} as const;

const metaDescription = document.querySelector("meta[name='description]");
//更新によりdb初期化
window.addEventListener('beforeunload', () => {
  store.dispatch(DispatchType.SET_DB, "")
})

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.title) {
      let title: string;
      if (AppData.jobList[Number(to.params.id)] === undefined) title = "";
      else title = AppData.jobList[Number(to.params.id)];
      document.title = AppData.appTitle + "|" + title + to.meta.description;
    } else {
      document.title = AppData.appTitle + "|" + "設定させていない場合のタイトル";
    }
    if (metaDescription && to.meta.description) {
      metaDescription.setAttribute("content", "to.meta.description"); /*, to.meta.description*/
    } else if (metaDescription) {
      metaDescription.setAttribute("content", "設定されていない場合のディスクリプション");
    }
    if (to.name === "Top" || to.name === "RHeartRoadTest" && from.name === "HeartResult") {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          window.scrollTo(0, store.getters.getPageY);//store.getters.getPageY
        },100)
      })
    } else {
      store.dispatch(DispatchType.SET_PAGE, to.name);
      store.dispatch(DispatchType.SET_PAGEY, window.scrollY);
      return { left: 0, top: 300 };
    }
    
  },
});

export default router;
