
import { defineComponent, computed, PropType } from "vue";
import { HeroData } from "@/store/app.config";

export default defineComponent({
  name: "PageHeroInner",
  props: {
    heroData: {
      type: Object as PropType<HeroData>,
      required: true,
    },
  },
  setup(props) {
    const hData = computed(() => props.heroData);
    return { hData };
  },
});
