
import { defineComponent, ref } from "vue";
import { menuRoutes } from "@/router/index";
import { RouteRecordRaw } from "vue-router";
export default defineComponent({
  name: "PageBreadcrumbs",
  props: {
    label: {
      type: String,
    }
  },
  setup(props) {
    const paths = ref<(string)[]>(["/"]);
    const names = ref<(string)[]>(["Top"]);
    const crumbs = location.pathname.split("/");
    var temp: RouteRecordRaw[] = [];
    for (const data of menuRoutes) {
      temp.push(data);
      if(data.children) {
        let j = 0;
        while (j < data.children.length) {
          temp.push(data.children[j])
          j++;
        }
      }
    }
    for (let i = 0; i < crumbs.length; i++) {//経路評価
      if (crumbs[i] in ["0", "1", "2", "3"]) {
        let temp = paths.value.pop();
        let num = crumbs[i];
        if(temp)paths.value.push(temp.replace(":id",num));
      }
      for (let j = 0; j < temp.length; j++) {
        if (temp[j].name === crumbs[i]) {
          if(typeof temp[j].meta?.title === "string") names.value.push(String(temp[j].meta?.title));
          paths.value.push(temp[j].path);
        } 
      }
    }
    return { paths, names};
  },
});
