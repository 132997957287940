
import { defineComponent, ref } from "vue";
import axios from "axios";
import { AppData } from "@/store/app.data";
import { PropType } from "@vue/runtime-core";
import store from "@/store";
import { DispatchType } from "@/store/app.config";
import { NewsData } from "@/store/app.config";

export default defineComponent({
  name: "RelatedNews",
  props: {
    connection: {
      type: Array as PropType<string[]>
    }
  },
  setup(props) {
    const RelatedNewsData = ref<NewsData[]>([]);
    
    const NewsClick = (newsdata: NewsData) => {
      store.dispatch(DispatchType.SET_PAGE, window.pageYOffset);
      store.dispatch(DispatchType.MODAL_MENU, newsdata);
      store.dispatch(DispatchType.SET_PAGE, "News");
      store.dispatch(DispatchType.SET_PAGEY, window.scrollY);
      location.reload();
      //router.push({ name: "News" });
    };
    if(props.connection && props.connection?.length > 0) {
      fetchData()
    }
    async function fetchData() {
      try {
        if(props.connection) {
          const { data } = await axios.get(AppData.searchPath + props.connection[0]);
          RelatedNewsData.value = data;
        }
      } catch(error) {
        console.log(error)
      }
    }
    return { RelatedNewsData, NewsClick };
  },
  
});
