import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50d4e2c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageBreadcrumbs = _resolveComponent("PageBreadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageBreadcrumbs),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pTitle, (data) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "Privacy__Title",
        key: data.title
      }, [
        _createElementVNode("div", {
          innerHTML: data.title
        }, null, 8, _hoisted_1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.contents, (contents) => {
          return (_openBlock(), _createElementBlock("div", {
            key: contents,
            innerHTML: contents
          }, null, 8, _hoisted_2))
        }), 128))
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pList, (data) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "Privacy__Items",
        key: data.title
      }, [
        _createElementVNode("h3", {
          innerHTML: data.title
        }, null, 8, _hoisted_3),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.contents, (contents) => {
          return (_openBlock(), _createElementBlock("div", {
            key: contents,
            innerHTML: contents
          }, null, 8, _hoisted_4))
        }), 128))
      ]))
    }), 128))
  ], 64))
}