import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-048e5764"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "News__tabPanel" }
const _hoisted_2 = { class: "News__list" }
const _hoisted_3 = { class: "News__itemInfo" }
const _hoisted_4 = ["datetime"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "News__MainContents" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_EyeCatching = _resolveComponent("EyeCatching")!
  const _component_NewsListContents = _resolveComponent("NewsListContents")!
  const _component_PagiNation = _resolveComponent("PagiNation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliceDate, (data, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: data.title.rendered,
            class: "News__item"
          }, [
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("time", {
                class: "News__itemDate",
                datetime: _ctx.getDateTime(data.date)
              }, _toDisplayString(_ctx.getDateTime(data.date)) + " |", 9, _hoisted_4),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.categories.length, (number) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: number,
                  class: _normalizeClass([_ctx.findObjectByKey(_ctx.newsCategories, 'id', data.categories[number-1]).acf.color, "News__itemCategory"])
                }, _toDisplayString(_ctx.findObjectByKey(_ctx.newsCategories, "id", data.categories[number-1]).name), 3))
              }), 128))
            ]),
            _createElementVNode("button", {
              class: "News__itemTitle",
              type: "button",
              onClick: ($event: any) => (_ctx.NewsClick(data))
            }, [
              _createElementVNode("span", null, _toDisplayString(data.title.rendered), 1)
            ], 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              (data.featured_media !== 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["News__EyeCatch", { '-eye': _ctx.judge[index]}])
                  }, [
                    _createVNode(_component_EyeCatching, {
                      featured_media: data.featured_media,
                      onChangeimg: ($event: any) => (_ctx.judge[index]=$event)
                    }, null, 8, ["featured_media", "onChangeimg"]),
                    _createElementVNode("img", { src: _ctx.eyecahchwidth }, null, 8, _hoisted_7)
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["News__Contents", { '-eye': _ctx.judge[index]}])
              }, [
                _createVNode(_component_NewsListContents, {
                  contents: data.content.rendered,
                  featured_media: data.featured_media
                }, null, 8, ["contents", "featured_media"])
              ], 2)
            ])
          ]))
        }), 128))
      ]),
      _createVNode(_component_PagiNation, {
        sub: _ctx.subDivision,
        "data-length": _ctx.filteredData.length,
        onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.subDivision = $event))
      }, null, 8, ["sub", "data-length"])
    ])
  ], 64))
}