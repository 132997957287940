import firebase from "firebase/app";
import { credentials, CredentialsMap } from "./credentials";
import { Loader } from "@googlemaps/js-api-loader";
//import "firebase/firestore";
//import "firebase/messaging";
export const loader = new Loader(CredentialsMap.config);
export const FirebaseApp: firebase.app.App = firebase.initializeApp(credentials.config);

/*
export const messaging = firebase.messaging()
messaging.getToken({
    vapidKey: credentials.messagingConfig.vapidKey
});

const db = firebase.firestore();
const dbName = "sample-app";
export const usersRef = db.collection(dbName);
*/
