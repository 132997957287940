
import { defineComponent, computed, PropType, ref, onMounted, onUnmounted } from "vue";
import { NewsCategories, NewsData, EmptyObject, DispatchType, NewsCategory } from "@/store/app.config";
import { getCategoryNewsData, getDataDateTime, findObjectByKey, getSliceFilteredData, getPaginationBlocks } from "@/store/app.utils";
import { AppData } from "@/store/app.data";
import PagiNation from "./PagiNation.vue";
import NewsListContents from "./NewsListContents.vue";
import EyeCatching from "./EyeCatching.vue";
import store from "@/store";
import router from "@/router";

export default defineComponent({
  name: "NewList",
  props: {
    newsData: {
      type: Object as PropType<NewsData[]>,
      required: true,
    },
    selectCategory: {
      type: Number,
      default: 0,
    },
    newsCategories: {
      type: Object as PropType<NewsCategories[]>,
      required: true,
    },
  },
  setup(props) {
    let jobList: string[] = AppData.jobList;
    let eyecahchwidth = ref<string>("");
    let subDivision = ref<number>(store.getters.subDivision);
    //let mainData = computed(()=>props.newsData);
    let mainData = [...props.newsData];
    const heroDataList = AppData.heroDataList.slice(1, -1);
    const filteredData = computed(() => {
      return getCategoryNewsData(mainData, props.selectCategory);
    });
    const sliceDate = computed(() => {
      const NewsDate = ref<NewsData[]>([]);
      const uniondata = getSliceFilteredData(filteredData.value, subDivision.value);
      const sliceDate = doStuff(uniondata, NewsDate.value);
      return sliceDate;
    });
    function doStuff(unions: NewsData[], list: NewsData[]) {
      for (const union of unions) list.push(union);
      return list;
    }
    var judge = ref<boolean[]>([]);
    for (let i = 0; i < sliceDate.value.length; i++) {
      judge.value.push(false);
    }
    const isModalOpen = computed(() => store.getters.isModalOpen);
    const cId = computed(() => store.getters.modalId);
    const ModalOpen = (id: number) => {
      store.dispatch(DispatchType.MODAL_OPEN);
      store.dispatch(DispatchType.SET_MODAL, id);
    };
    let blocks = computed(() => {
      return getPaginationBlocks(filteredData.value.length);
    });
    let sub = ref("message");
    const NewsClick = (newsdata: NewsData) => {
      store.dispatch(DispatchType.MODAL_MENU, newsdata);
      store.dispatch(DispatchType.SET_PAGE, "News");
      router.push({ name: "News" });
      //router.push({ path: '/News' })
    };
    return { 
             filteredData, isModalOpen, ModalOpen, cId, blocks, sliceDate,
             subDivision, sub, NewsClick, eyecahchwidth, judge, jobList,
             heroDataList,
           };
  },
  methods: {
    findObjectByKey(categories: NewsCategories[], key: keyof NewsCategories, value: number): NewsCategories | EmptyObject {
      return findObjectByKey(categories, key, value);
    },
    
    getDateTime(dateStr: string): string {
      return getDataDateTime(dateStr);
    },
  },
  components: {
    PagiNation,
    NewsListContents,
    EyeCatching,
  },
});
