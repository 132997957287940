
import store from '@/store';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { Trie } from '@/store/app.utils';
import { getApi, sortImgDB, binarySearch } from '@/store/app.utils';
import { imgData } from '@/store/app.config';
import DetailOfHeart from '@/components/pages/DetailOfHeart.vue';
import TopButton from '@/components/button/TopButton.vue';
import PageBreadcrumbs from '@/components/pages/PageBreadcrumbs.vue';
import ModalOverlay from '@/components/pages/ModalOverlay.vue';
import Infomation from '@/components/loading/Infomation.vue';
import useWindowSize from "@/composable/use-window-size";
import Indicator from '@/components/loading/Indicator.vue';

export default defineComponent({
  
    setup() {
      let nowLoad = ref(true);
      let readyDB = ref(false);
      let monsterName = ref("");
      let result = ref<Record<string,string>[]>([]);
      async function createDB () {
        await getApi();
        nowLoad.value = false;
        readyDB.value = true;
      }
      createDB();
      const state = reactive<Record<string, number>>({
        "最大HP":0,
        "最大MP":0,
        "ちから":0,
        "みのまもり":0,
        "攻撃魔力":0,
        "回復魔力":0,
        "すばやさ":0,
        "きようさ":0,
      })
      const onOpenMenu = ref<boolean>(false);
      const openMenu = () => {
        onOpenMenu.value = true;
      }
      const blist = ref<boolean[]>([]);
      //詳細表示用のON/OFF配列、配列は指定した要素がなくてもundefindを返すため空配列でもswitingに使える

      const detail = (index: number) => {
        blist.value[index] = !blist.value[index];
      }
      let wide = ref<boolean>(false);
    
      const setPos = (dw: number) => {
        wide.value = dw > 768;
      }
      const { dw } = useWindowSize(() => {
        setPos(dw.value);
      });
      setPos(dw.value);
      const Search = (status: string) => {
        const trie = new Trie();
        const dqwdb: Record<string, string>[] = store.getters.dbCaching;
        const imgList: imgData[] = store.getters.imgDb;
        const sortData = sortImgDB(imgList);
        
        dqwdb.forEach((item) => {
          item.url = binarySearch(sortData, item)
        });
        dqwdb.forEach((data) => {
          if (Object.prototype.hasOwnProperty.call(data, status)) {
            trie.addValue(data[status], data);
          }
        });
        
        result.value = trie.wide(monsterName.value);
      }
      return { onOpenMenu, monsterName, result,
               openMenu, 
               Search,
               ...toRefs(state), state,
                nowLoad, blist, detail,
               wide }
    },
    methods: {
      imgSrc() {
          return require("../assets/no_imag.jpg");
      }
     },
    components: { DetailOfHeart, TopButton, PageBreadcrumbs, ModalOverlay, Infomation, Indicator },
})
