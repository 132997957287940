import { loader } from "@/firebase/app";
import store from "@/store";
import { DispatchType } from '@/store/app.config';

export async function getMaps() {
  await refPosition();
}
export async function posAgree() {
  await getPosition();
  const google = store.getters.google;
  const map = store.getters.map;
  const latlng = new google.maps.LatLng(lat, lng);
  map.setCenter(latlng);
}
async function refPosition() {
  await loader.load().then((google) => {
    const mapDiv = document.getElementById("map") as HTMLElement;
    const map = new google.maps.Map(mapDiv, {
      center: { lat: 35.6894, lng: 139.6917 },
      zoom: 14,
      mapTypeControl: false,
      fullscreenControl: false,
      gestureHandling: "greedy",
    });

    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYLINE,
      drawingControl: true,
      drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [
          google.maps.drawing.OverlayType.POLYLINE,
      ],
      },
      markerOptions: {
        visible: false ,
        icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      },
      polylineOptions: {
        geodesic: true,
        strokeColor: "#000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      },
    });
    drawingManager.setMap(map);  

    store.dispatch(DispatchType.SET_MANAGER, drawingManager);
    store.dispatch(DispatchType.SET_MAP, map);
    store.dispatch(DispatchType.SET_GOOGLE, google);

    }).catch((e) => {
    console.log("error",e)
  })
}
let lat: number, lng: number, position: any;

async function getPosition() {
  position = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  })
  .catch((e) => {
    console.log(e);
    lat = 35.6894;
    lng = 139.6917;
  })
  if(!lat) {
    lat = position.coords.latitude;
    lng = position.coords.longitude;
  }
  
}

