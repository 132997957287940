
import { computed, defineComponent, ref, onMounted, onUnmounted } from "@vue/runtime-core";
import store from "@/store";
import router from "@/router";
import useWindowSize from "@/composable/use-window-size";
import PageCarouselItem from "./PageCarouselItem.vue";
import PageBreadcrumbs from "./PageBreadcrumbs.vue";
import DetailOfHeart from "./DetailOfHeart.vue";
import { getApi, sortImgDB, binarySearch } from "@/store/app.utils";
import ModalOverlay from "./ModalOverlay.vue";
import Infomation from "../loading/Infomation.vue";
import RecentNews from "@/views/RecentNews.vue";
import ProfileData from "../../views/ProfileData.vue";
import RelatedNews from "@/views/RelatedNews.vue";
import BulletionBoard from "@/views/BulletionBoard.vue";
import { ArticleNews, imgData } from "@/store/app.config";
import SpinLoding from "../loading/SpinLoding.vue";
import ArrowRight from "../slider/ArrowRight.vue";
import ArrowLeft from "../slider/ArrowLeft.vue";

export default defineComponent({
  name: "PageModalNews",
  emits: ["changeModal"],
  setup() {
    const newsData: ArticleNews = store.getters.modalMenu;
    const title = newsData.title.rendered;
    const splitList = newsData.content.rendered.split(/\n/);
    
    const srcList = ref<string[][]>([]);
    const contentList = ref<string[][]>([]);
    let swiching = ref<boolean>(false);
    let i = -1;
    let j = -1;
    let stringList: string[] = [];

    let dqwdb: Record<string, string>[];
    let nowLoad = ref<boolean>(true);
    async function createDB () {
      try {
        await getApi()
        dqwdb = [...store.getters.dbCaching];
        const imgList: imgData[] = store.getters.imgDb;
        const sortData = sortImgDB(imgList);
        dqwdb.forEach(data => {
          data.url = binarySearch(sortData, data);
        })
        nowLoad.value = false;
      } catch(error) {
        nowLoad.value = false;
        console.log(error);
      }
    }
    createDB();

    var blist = ref<boolean>(false);
    var bdata = ref<Record<string, string>>();
    var monsterList = ref<any>([]);
    var connection: string[] = [];
    for (const data of splitList) {
      
      if (/<p>&/.test(data)) {//buttonの取り出し
        const str = data.split('<p>')[1].split('</p>')[0]
                    .replace(/&(lt);/g, '<')
                    .replace(/&(gt);/g, '>')
                    .replace(/&#(8221|8217);/g, '"')
        if (!swiching.value) {
          stringList = [];
          contentList.value.push(stringList);
          i ++;
        }
        contentList.value[i].push(str);
        swiching.value = true;
      } else if (/<p>関連ワード/.test(data)){//関連ワードの取り出し
        connection = data.split('<p>')[1].split('</p>')[0].split("、").slice(1);
      } else if (/<p|<h[1-4]|<li|<table/.test(data)) {//その他取り出し
        if (!swiching.value) {
          stringList = [];
          contentList.value.push(stringList);
          i ++;
        }
        contentList.value[i].push(data);
        swiching.value = true;
      } else if (/src=/.test(data)) {
        if (swiching.value) {
          stringList = [];
          srcList.value.push(stringList);
          j ++;
        }
        srcList.value[j].push(data);
        swiching.value = false;
      }
    }
    var imgDubble = ref<boolean>(false);
    var scrollAmount = ref<number>(1);
    var nextButton = ref<boolean[]>([]);
    var prevButton = ref<boolean[]>([]);
    const setPos = (dw: number) => {
      nextButton.value = [];
      prevButton.value = [];
      if (dw > 768) {
        imgDubble.value = true;
        scrollAmount.value = 2;
      } else {
        imgDubble.value = false;
        scrollAmount.value = 1;
      }
      
      nextButton.value = srcList.value.map((src) => src.length > scrollAmount.value)
      
    };
    const { dw } = useWindowSize(() => {
      setPos(dw.value);
    });
    setPos(dw.value);
    
    const carouselRef = ref<HTMLElement>();
    const hasCarousel = ref<boolean[]>([]);
    var nowCarouselNum = ref<number>(0);
    const onClickNext = (index: number) => {
      nowCarouselNum.value ++;
      prevButton.value[index] = true;
      if (Math.ceil((srcList.value[index].length - 1) / scrollAmount.value) <= nowCarouselNum.value) nextButton.value[index] = false;
    }
    const onClickPrev = (index: number) => {
      nowCarouselNum.value --;
      nextButton.value[index] = true;
      if (nowCarouselNum.value <= 0) prevButton.value[index] = false;
    }
     onMounted(() => {
      monsterList.value = document.querySelectorAll('.Monster');
      for (let i = 0; i < monsterList.value.length; i++) {
        monsterList.value[i]?.addEventListener('click', (e:any) => {
          let path = e.composedPath()[0] as HTMLElement;
          const data = dqwdb.find(item => item["名前"] === path.innerText);
          if (data) {
            bdata.value = data;
            blist.value = true;
          }
        })
      }
     });
    onUnmounted(() => {
      for (let i = 0; i < monsterList.value.length; i++) {
        monsterList.value[i].remove();
      }
    });
    return { 
             newsData, splitList, srcList, contentList, 
             carouselRef, hasCarousel,
             blist, bdata, title, nowLoad,
             onClickNext, onClickPrev, nowCarouselNum, imgDubble, nextButton, prevButton,
             connection,
           };
  },
  components: {
    PageCarouselItem, PageBreadcrumbs, DetailOfHeart, ModalOverlay, Infomation,
    ProfileData, RecentNews, RelatedNews, BulletionBoard, SpinLoding,
    ArrowRight,
    ArrowLeft,
  },
});
