import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-545404b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PageHero" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeroInner = _resolveComponent("PageHeroInner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeroInner, {
      heroData: _ctx.heroDataList[0]
    }, {
      heading: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.heroDataList[0].headingHtml
        }, null, 8, _hoisted_2)
      ]),
      desc: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.heroDataList[0].descHtml
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["heroData"])
  ]))
}