
import { defineComponent, ref, SetupContext } from 'vue';
import { AppData } from '@/store/app.data';
import axios, { AxiosResponse, AxiosError } from 'axios';
import EyeCatchingImg from './EyeCatchingImg.vue';
import { EyeCatching, NewsCategories, NewsData } from '@/store/app.config';

export default defineComponent({
    name: "EyeCathing",
    props: {
        featured_media: {
          type: Number,
        }
    },
    setup(props, context: SetupContext) {
        var thumbnail = ref<string>("");
        var width = ref<number>(1);
        var height = ref<number>(1);
        var imgSize = ref<boolean>(false);
        const url = AppData.eyecatch + props.featured_media;
        if (props.featured_media !== 0 ) {
          axios
          .get(url)
          .then((res: AxiosResponse<EyeCatching>) => {
            width.value = res.data.media_details.width;
            height.value = res.data.media_details.height;
            thumbnail.value = "https" + res.data.guid.rendered.split("http")[1];
            if (width.value / height.value >= 1.1) imgSize.value = true;
            else imgSize.value = false;
            context.emit("changeimg",imgSize.value);
          })
          .catch((e: AxiosError<{ error: string }>) => {
              console.log(e)
          })
        }
        return { thumbnail, imgSize, width, height }
    },
    components: {
      EyeCatchingImg,
    }
})
