
import { defineComponent, ref, watch } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { NewsCategories, NewsData } from "@/store/app.config";
import { AppData } from "@/store/app.data";
import NewsTab from "@/components/pages/NewsTab.vue";
import NotFound from "@/components/pages/NotFound.vue";
import SearchArticle from "@/components/sub/SearchArticle.vue";
import ProfileData from "./ProfileData.vue";
import RecentNews from "./RecentNews.vue";
import ModalOverlay from "@/components/pages/ModalOverlay.vue";
import Infomation from "@/components/loading/Infomation.vue";

export default defineComponent({
  name: "SearchResult",
  setup() {
    const newsData = ref<NewsData[]>([]);
    const newsCategories = ref<NewsCategories[]>([]);
    const route = useRoute();
    var keyword = ref(route.query);
    var nowLoad = ref<boolean>(true);
    all();
    async function all() {
      try {
        const [ Data, Categories ] = await axios.all([apiNews(), apiCate()]);
        nowLoad.value = false;
        newsData.value = Data;
        newsCategories.value = Categories;
      } catch (error) {
        console.log(error);
      }
    }

    async function apiNews() {
      const { data } = await axios.get(`${AppData.searchPath}${keyword.value.search}`);
      return data;
    }

    async function apiCate() {
      const { data } = await axios.get(AppData.newsCategoriesPath);
      return data;
    }

    watch(
      () => route.query,
      () => location.reload()
    );
    return { newsData, newsCategories, keyword, nowLoad };
  },
  components: {
    NewsTab,
    NotFound,
    SearchArticle,
    ProfileData,
    RecentNews,
    ModalOverlay,
    Infomation,
  },
});
