import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62fcec18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Modal -show" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_overlay = _resolveComponent("modal-overlay")!
  const _component_DetailOfHeartResult = _resolveComponent("DetailOfHeartResult")!
  const _component_close_button = _resolveComponent("close-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_modal_overlay),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DetailOfHeartResult, {
        data: _ctx.$props.data
      }, null, 8, ["data"]),
      _createVNode(_component_close_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCloseModal()))
      })
    ])
  ], 64))
}