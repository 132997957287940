
import { computed, defineComponent, ref, SetupContext } from "@vue/runtime-core";
import store from "@/store";
import { DispatchType } from "@/store/app.config";

export default defineComponent({
  name: "PageNation",
  props: {
    sub: {
      type: Number,
      required: true,
    },
    dataLength: {
      type: Number,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    var select = ref<boolean[]>([]);
    const displays = store.getters.displays; //表示数
    let dequeBox = computed(() => {
      let buff = ref<number[]>([]);
      let blocks = Math.ceil(props.dataLength / displays);
      const maxBlocks = store.getters.maxBlocks;
      if (blocks < maxBlocks) {
        for (let i = 0; i < blocks; i++) {
          buff.value.push(i + 1);
          select.value.push(false);
        }
      } else {
        for (let i = 0; i < maxBlocks; i++) {
          buff.value.push(i + 1);
          select.value.push(false);
        }
      }
      return buff.value;
    });
    select.value[props.sub - 1] = true;
    
    const clickDeque = (i: number) => {

      for (let j=0; j < select.value.length; j++) {
        select.value[j] = false;
      }
      select.value[i - 1] = true;
      store.dispatch(DispatchType.SET_SUB, i);
      context.emit("change-page", i);
      let mid = Math.floor(dequeBox.value.length / 2);
      let carry = i - dequeBox.value[mid];
      const maxBlocks = store.getters.maxBlocks;
      if (dequeBox.value.length > maxBlocks) {
        if (carry > 0) {
          for (let j = 0; j < carry; j++) {
            let tem: number | undefined = dequeBox.value.shift();
            if (tem !== undefined) dequeBox.value.push(tem + maxBlocks);
          }
        } else if (carry < 0 && dequeBox.value[0] >= 2) {
          //[1,2,3,4,5],[2,3,4,5,6]
          if (dequeBox.value[0] === 2) carry = 1;
          for (let j = 0; j < Math.abs(carry); j++) {
            let tem: number | undefined = dequeBox.value.pop();
            if (tem !== undefined) dequeBox.value.unshift(Math.abs(maxBlocks - tem));
          }
        }
      }
    };
    return {
      clickDeque,
      dequeBox,
      select,
    };
  },
});
