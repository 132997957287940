
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

export default defineComponent({
  name: "TopButton",
  setup() {
    let pageTop = ref<boolean>(false);
      function func() {
        if (window.scrollY >= 600) {
          pageTop.value = true;
        } else {
          pageTop.value = false;
        }
      }
    const scrollTop = () => {
      window.scroll({ top: 400, behavior: "smooth" });
    }
    onMounted(() => {
      window.addEventListener("scroll", func, {passive: true});
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", func);
    })
    return { pageTop, scrollTop }
  },
})
