
import { defineComponent } from 'vue';
import { AppData } from '@/store/app.data';
import PageBreadcrumbs from '../pages/PageBreadcrumbs.vue';
import router from '@/router';

export default defineComponent({
    setup() {
      const majan = AppData.toolList.slice(0,1)[0];
      const toolList = AppData.toolList.slice(1);
      return { toolList, majan }
    },
    components: {
      PageBreadcrumbs,
    }
})
