
import Infomation from '@/components/loading/Infomation.vue';
import ModalOverlay from '@/components/pages/ModalOverlay.vue';
import { AppData } from '@/store/app.data';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { imgData } from '@/store/app.config';
import { gsReferenceFriendly } from '@/firebase/storage';
import useWindowSize from '@/composable/use-window-size';
import { Trie } from '@/store/app.utils';
import PageBreadcrumbs from '@/components/pages/PageBreadcrumbs.vue';
import InputName from './InputName.vue';
import SortUi from './SortUi.vue';
import Detail from './MonsterPartyDetails.vue';
import TopButton from '@/components/button/TopButton.vue';
import Indicator from '@/components/loading/Indicator.vue';


export default defineComponent({
  components: { ModalOverlay, Infomation, PageBreadcrumbs, SortUi, TopButton, Indicator, Detail, InputName },
    setup() {
        const change = (...args:[any, number]) => {
            toInputName.value = [];
            Partys.value[args[1]].name = args[0];
            localStorage.setItem('Partys', JSON.stringify(Partys.value));
        }
        let toInputName = ref<boolean[]>([]);
        let partyName = ref<string>("テスト");
        let openDetail = ref<boolean[]>([]);
        const DetailOpen = (index: number) => {
            openDetail.value[index] = !openDetail.value[index];
            toInputName.value[index] = !toInputName.value[index];
        }
        let selectValue = ref(0);
        let Partys = ref<{name: string, list: any[]}[]>([{name: "パーティー1", list: []},
                                                         {name: "パーティー2", list: []},
                                                         {name: "パーティー3", list: []},
                                                         {name: "パーティー4", list: []},
                                                        ]);
        if(localStorage.getItem('selectValue')) {
            selectValue.value = Number(localStorage.getItem('selectValue'));
        }
        if(localStorage.getItem('Partys')) {
            Partys.value = JSON.parse(String(localStorage.getItem('Partys')))
        }
        let selectParty = ref<boolean[]>([]);
        const PartyChange = (index: number) => {
            localStorage.setItem('selectValue', String(index));
            selectValue.value = index;
            selectParty.value = [];
            selectParty.value[index] = true;
            holding.value = [];
            Partys.value[index].list.forEach((data) => {
                holding.value[Number(data["No"]) - 1] = true;
            })
        }
        const addMember = (data: any, index: number) => {
            holding.value[index] = !holding.value[index];
            if (!holding.value[index]) {
                Partys.value[selectValue.value].list = Partys.value[selectValue.value].list
                                    .filter((listData: any) => listData["名前"] !== data["名前"])
            } else if(Partys.value[selectValue.value].list.length > 3) {
                holding.value[index] = !holding.value[index];
                return
            } else {
                Partys.value[selectValue.value].list.push(data);
            }
            localStorage.setItem('Partys', JSON.stringify(Partys.value));
        }
        
        let MonsterName = ref("");
        let nowLoad = ref(true);
        const result = ref<any[]>([]);
        const refData = ref<any[]>([]);
        
        const BtnOfAttriSelect = ref<boolean[]>([]);
        const attribute = ["メラ", "ギラ", "ヒャド", "バギ", "デイン", "ジバリア", "イオ", "ドルマ"];
        const attributton = [...attribute];
        
        const BtnOfstateSelect = ref<boolean[]>([]);
        const state = ["眠り", "麻痺", "混乱", "幻惑", "毒", "即死", "呪い", "封印", "休み", "魅了", "攻撃減", "守備減", "すばやさ減", "呪文耐性減"];
        const attricolor:any = {"メラ": "-red", "ギラ": "-brightRed", "ヒャド": "-blue", "バギ": "-green",
                                "デイン": "-yellow", "ジバリア": "-brown", "イオ": "-orange", "ドルマ": "-purple",}
        const userSelectList = ref<string[]>([]);
        const weakpoint = ["弱点", "等倍", "耐性"];
        const abnormal = ["×", "△", "▲", "◯", "◎", "☆"];
        let widesize = ref(false);
        const setPos = (dw: number) => {
            widesize.value = dw > 768 ? false : true;
        };
        const { dw } = useWindowSize(() => {
            setPos(dw.value);
        });
        setPos(dw.value);
        const allData = () => {
            memoryAttri = "";
            memoryState = "";
            BtnOfAttriSelect.value = [];
            BtnOfstateSelect.value = [];
            userSelectList.value = [];
            holding.value = [];
            if (holdList.value.length > 0) {
                for(let i = 0; i < holdList.value.length; i++) {
                    holding.value.push(true);
                }
            }
            result.value = holdList.value.length > 0 ? holdList.value.concat(refData.value
                                                                     .filter(data => !holdList.value.includes(data)))             
                                                     : refData.value;
            getIndexHolding(result.value);
        }

        let holdList = ref<any[]>([]);
        const holding = ref<boolean[]>([]);//ボタンの明暗
        let memoryAttri = "";
        let memoryState = "";
        let cationAttri = ref(false);
        let cationState = ref(false);
        const weakSearch = (word: string, index: number) => {
            if (!userSelectList.value.length && weakpoint.includes(word)) {
                cationAttri.value = true;
                setTimeout(() => {
                    cationAttri.value = false;
                }, 1000);
                return
            }
            if (!userSelectList.value.length && abnormal.includes(word)) {
                cationState.value = true;
                setTimeout(() => {
                    cationState.value = false;
                }, 1000);
                return
            }
            if (weakpoint.includes(word)) memoryAttri = word;
            if (abnormal.includes(word)) memoryState = word;
            holding.value = [];
            if (holdList.value.length > 0) {
                for(let i = 0; i < holdList.value.length; i++) {
                    holding.value.push(true);
                }
            }
            result.value = [...refData.value];
            const selectAttri = userSelectList.value.filter(data => attribute.includes(data));
            const selectState = userSelectList.value.filter(data => state.includes(data));
            if (memoryAttri !== "") {
                result.value = result.value.filter(data => {
                    for (const use of selectAttri) {
                        if (memoryAttri === "弱点" && data[use] < 0) {
                            continue;
                        } else if (memoryAttri === "等倍" && data[use] === "") {
                            continue;
                        } else if (memoryAttri === "耐性" && data[use] > 0) {
                            continue;
                        } else {
                            return false;
                        }
                    }
                    return true;
                })
            }
            if (memoryState !== "") {
                result.value = result.value.filter(data => {
                    for (const use of selectState) {
                        if (data[use] === memoryState) {
                            continue;
                        } else {
                            return false;
                        }
                    }
                    return true;
                })
            }
            result.value = holdList.value.concat(result.value.filter(data => !holdList.value.includes(data)));
            getIndexHolding(result.value);
        }
        const userSelect = (item: string, index: number) => {
            if (attribute.includes(item)) BtnOfAttriSelect.value[index] = !BtnOfAttriSelect.value[index];
            if (state.includes(item)) BtnOfstateSelect.value[index] = !BtnOfstateSelect.value[index];
            if (!userSelectList.value.includes(item)) {
                userSelectList.value.push(item);
            } else {
                userSelectList.value = userSelectList.value
                                   .filter(data => data !== item)
            }
        }
        const Search = () => {
            result.value = trie.wide(MonsterName.value);
        }
        const Reset = () => {
            result.value = [...refData.value];
            holding.value = [];
            getIndexHolding(result.value);
        }
        function getIndexHolding(array: any[]) {
            array.forEach((data, index) => {
                Partys.value[selectValue.value].list.forEach((party) => {
                    if(data["名前"] === party["名前"]) {
                        holding.value[index] = true;
                    }
                })
            })
        }
        async function getResponse() {
            try {
                const { data } = await axios.get(AppData.friendlyDataResist);
                result.value = [...data];
                refData.value = [...data];
                getIndexHolding(result.value);
            } catch(e) {
                console.log(e);
            }
        }
        const imgList: imgData[] = [];
        async function getImgApi() {
          try {
            const { items } = await gsReferenceFriendly.listAll();
            const promise = items.map((item: any) => item.getDownloadURL());
            const imgArray = await Promise.all(promise)
                    //.catch(error => console.log("Error fetching all download URLs: ", error));
            for (let i = 0; i < imgArray.length; i++) {
                const num = imgArray[i].split("?")[0].split("F")[1].split(".")[0];
                imgList.push({"num": Number(num), "url": imgArray[i]});
            }
            //console.log("strageOk",imgList); 
            //store.dispatch(DispatchType.SET_IMG, imgList);
          } catch(error) {
            console.log(error);
          } 
        }
        function timeout(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        let trie = new Trie();
        async function getData() {
            await Promise.all([
                  Promise.race([getResponse(), timeout(5000)]),
                  Promise.race([getImgApi(), timeout(5000)]),
                  ])
            result.value.forEach(value => {
              for(const data of imgList) {
                if (value["No"] === data.num) value["url"] = data.url;
              }
              trie.addValue(value["名前"], value);
            })
            
            nowLoad.value = false;
        }
        getData();
        return { widesize, Search, MonsterName, cationAttri, cationState,
                 allData, abnormal,
                 nowLoad, result, holdList, holding,
                 attribute, attributton, attricolor, weakpoint, state,
                 BtnOfAttriSelect, BtnOfstateSelect,
                 userSelect, weakSearch, Reset,
                 Partys, addMember,selectParty, 
                 PartyChange, selectValue, openDetail, DetailOpen, partyName,
                 toInputName, change }
    },
})
