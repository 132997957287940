
import CloseButton from '@/components/button/CloseButton.vue';
import ModalOverlay from '@/components/pages/ModalOverlay.vue';
import { ref, defineComponent, PropType } from 'vue';

export default defineComponent({
    name: "FriendlyMonsterDetail",
    props: {
      data: {
        type: Object as PropType<any>,
        required: true,
      }
    },
    emits: ["changeModal"],
    setup(props, context) {
      let outputData: any[] = [];
      const resist = ["耐属性", "耐系統", "耐攻撃", "耐変化", "耐異常", "耐特殊"];
      const attack = ["斬体", "呪文", "ブレス"];
      const status = ["最大HP",	"最大MP", "攻撃力",	"守備力", "素早さ",	"攻撃魔力",	"回復魔力", "器用さ"];
      const others = ["回復", "成功率"];
      const result = Object.keys(props.data)
                           .filter(key => props.data[key] !== "");
      result.forEach((data) => {
        if (data === "url" || data === "名前") {
          return; 
        } else if (data === "No") {
          outputData.push(`No${ props.data["No"] }  ${ props.data["名前"] }`);
        } else if (attack.includes(data) || status.includes(data) || others.includes(data)) {
          props.data[data].split("、").forEach((value: string) => {
            outputData.push(`${ data } ${ value }`);
          });
        } else if (data === "撃系統") {
          props.data[data].split("、").forEach((value: string) => {
            outputData.push(`撃 • ${ value }`);
          });
        } else if (data === "全属性") {
          props.data[data].split("、").forEach((value: string) => {
            outputData.push(`全 • ${ value }`);
          });
        } else if (resist.includes(data)) {
          props.data[data].split("、").forEach((value: string) => {
            outputData.push(`耐 • ${ value }`);
          });
        } else {//確率、ターン回復
          props.data[data].split("、").forEach((value: string) => {
            outputData.push(`${ value }`);
          });   
        }
      })
      const MonsterName = outputData[0];
      outputData.splice(0, 1);

      const onCloseModal = () => {
        context.emit("changeModal");
      };
      return { result, onCloseModal, outputData, MonsterName };
    },
    components: { CloseButton, ModalOverlay }
})
