import { BnrData, HeroData, infoData } from "@/store/app.config";

export class AppData {
  public static test = "https://dqwstepsaday.com/wp-json/wp/v2/posts/?before=2022-03-01T00:00:00&&after=2022-02-01T00:00:00";
  public static newsPath = "https://dqwstepsaday.com/wp-json/wp/v2/posts?per_page=20";
  public static refDate = "https://dqwstepsaday.com/wp-json/wp/v2/posts/?order=asc";
  public static newsCategoriesPath = "https://dqwstepsaday.com/wp-json/wp/v2/categories/";
  public static searchPath = "https://dqwstepsaday.com/wp-json/wp/v2/posts?search=";
  public static eyecatch = "https://dqwstepsaday.com/wp-json/wp/v2/media/";
  public static appTitle = "【ドラクエウォーク】守護天使こころ道一覧";

  public static dqwData = "https://script.google.com/macros/s/AKfycbwiAiLFUgNIfxW5zIk8XC3gX2kol7dXqzhRL8-5akS1tBIizrgyyn-hptjPTj_ZRTez/exec";
  public static friendlyData = "https://script.google.com/macros/s/AKfycbwHaJJLnkfa6fvnBQA7H2iZjaColzY-AASdwFKqWmBlxLtVDLdPRKsakI4B_XWEp-lxfg/exec";
  public static friendlyDataResist = "https://script.google.com/macros/s/AKfycbwf81l3-Wa1QJ-MDHc3luENW7vwK97-ZX-DR_LMSrv1OcYQOOp7tMBBSD9WvPhmAldl_w/exec";

  public static jobList = ["ゴッドハンド","大魔道士","大神官","ニンジャ","魔剣士","守護天使"];
  public static color = [
    [["all","none"],["red","yellow"],["green","purple"],["blue","red"],["all","all"],["purple","green"],["yellow","blue"],["green", "yellow"]],
    [["all","none"],["yellow","purple"],["purple","green"],["red","blue"],["all","all"],["green","red"],["blue","yellow"],["yellow", "purple"]],
    [["all","none"],["blue","red"],["red","yellow"],["yellow","purple"],["all","all"],["green","blue"],["purple","green"],["blue", "red"]],
    [["all","none"],["purple","green"],["blue","red"],["green","yellow"],["all","all"],["yellow","blue"],["red","purple"],["purple", "blue"]],
    [["all","none"],["blue","green"],["yellow","blue"],["green","purple"],["all","all"],["purple","red"],["red","yellow"],["green", "red"]],
    [["all","none"],["red","purple"],["blue","yellow"],["green","blue"],["all","all"],["yellow","red"],["purple","green"],["yellow", "purple"]],
  ];
  public static status = [
    [
      [["最大HP+15","みのまもり+15","ちから+15","ゴッドレイジ発動率+2%"],["","","",""]],
      [["ゴッドレイジ威力アップ+15%","ゴッドレイジ威力アップ+10%","最大MP+5","すばやさ+5"],["ゴッドガード発動率+5%","ゴッドガード発動率+3%","みのまもり+20","ちから+20"]],
      [["すてみ（受ける斬撃体技ダメージがかなり増えるが、攻撃力がかなり上がる）","最大MP+5さらにバトマスの職業熟練度2以上で+15","すばやさ+5","ちから+15"], ["もくそう（自分の守備力をかなり上げ、ターン開始時HP回復）","みのまもり+15さらにパラディンの職業熟練度2以上で+10","最大HP+15","ちから+15"]],
      [["ゴッドレイジ発動率+3%","ゴッドレイジ発動率+2%","最大HP+15","スキル斬撃・体技ダメージ+2%"],["ゴッドガードダメージ軽減率+10%","ゴッドガードダメージ軽減率+5%","ちから+20","スキル斬撃・体技ダメージ+3%"]],
      [["戦鬼解放（自分の斬撃体技ダメージを上げるが受けるHP回復効果が下がり命令できなくなる）","幻惑耐性+3%","すばやさ+5さらにバトマスの職業熟練度4以上で+15","ちから+20"],["騎士道精神（自分の斬撃体技ダメージをかなり上げ、ガード率を少し上げる　パラディンガード発動の度に効果が大きくなる）","ちから+25さらにパラディンの職業熟練度4以上で+10","最大HP+15","スキル斬撃・体技ダメージ+2%"]],
      [["ゴッドレイジ威力アップ+10%","ゴッドレイジ威力アップ+5%","ちから+10さらにバトマスの職業熟練度6以上で+10","スキル斬撃・体技ダメージ+3%"],["ゴッドガード発動率+5%","ゴッドガード発動率+3%","最大HP+15","みのまもり+20"]],
      [["ゴッドレイジ発動率+3%","ゴッドレイジ発動率+2%","呪い耐性+3%","会心率+2%"],["ゴッドガードダメージ軽減率+3%","ゴッドガードダメージ軽減率+2%","最大HP+20さらにパラディンの職業熟練度6以上で+15","ガード率+1%"]],
      [["スキル斬撃・体技ダメージ+2%","すばやさ+10","最大MP+15さらにバトマスの職業熟練度8以上で+15","ちから+5"],["斬撃・体技+2%","ちから+10","すばやさ+15さらにパラディンの職業熟練度8以上で+15","最大HP+10"]]
    ],
    [
      [["最大HP+15", "すばやさ+15", "攻撃魔力+15", "全属性ダメージ+2%"], ["", "", "", ""]],
      [["魔神のやまびこの攻撃呪文威力+10%", "魔神のやまびこの攻撃呪文威力+5%", "攻撃魔力+15", "すばやさ+10"], ["フォースブレイク1段階目成功率+10%", "フォースブレイク1段階目成功率+5%", "最大MP+30", "全ての状態異常成功率+5%"]],
      [["すばやさ+15", "攻撃魔力+15", "最大HP+15さらに賢者の職業熟練度2以上で+15", "攻撃魔力+10"], ["まれに使用スキルの消費MPの50%回復", "ぶきみなひかり (敵1体の攻撃呪文耐性をまれに下げる)", "みのまもり+30さらに魔法戦士の職業熟練度2以上で+10", "呪文ダメージ+3%"]],
      [["攻撃呪文の魔神のやまびこ発動率+3%", "攻撃呪文の魔神のやまびこ発動率+2%", "呪文ダメージ+2%", "攻撃魔力+15さらに賢者の職業熟練度4以上で+10"], ["フォースブレイク2段階目成功率+5%", "フォースブレイク2段階目成功率+3%", "ちから+36", "全属性ダメージ+5%"]],
      [["魔響の詠唱 (自分の攻撃呪文威力が少し上がりやまびこが発動しやすくなるがMP消費が増加)", "呪文ダメージ+2%", "封印耐性+5%", "呪文ダメージ+1%"], ["フォースバースト(自分の全属性ダメージを上げフォースブレイク1段階目成功率を大幅に上げる)", "最大MP+30さらに魔法戦士の職業熟練度4以上で+15", "最大HP+20", "暴走魔法陣 (自分と両隣りにいる味方の魔力暴走率を上げる)"]],
      [["魔神のやまびこの攻撃呪文威力アップ+10%", "魔神のやまびこの攻撃呪文威力アップ+5%", "攻撃魔力+20さらに賢者の職業熟練度6以上で+10", "魔力の暴走率+1%"], ["フォースブレイク1段階目成功率+10%", "フォースブレイク1段階目成功率+5%", "攻撃魔力+20", "フォースブレイク最大ターン+1"]],
      [["攻撃呪文の魔神のやまびこ発動率+3%", "攻撃呪文の魔神のやまびこ発動率+2%", "怯え耐性+3%", "3%の確率で自分が受けた呪文を敵に跳ね返す"], ["フォースブレイク2段階目成功率+4%", "フォースブレイク2段階目成功率+3%", "最大HP+40さらに魔法戦士の職業熟練度6以上で+15", "全属性耐性+1%"]],
      [["呪文ダメージ+2%", "攻撃魔力+10", "最大MP+15さらに賢者の職業熟練度8以上で+15", "すばやさ+10"], ["ギラ属性ダメージ+3%", "デイン属性ダメージ+3%", "最大HP+15さらに魔法戦士の職業熟練度8以上で+15", "攻撃魔力+5"]]
    ],
    [
      [["最大HP+15", "最大MP+15", "みのまもり+15", "すばやさ+15"], ["", "", "", ""]],
      [["マヌーハ (仲間1人の幻惑をなおす)", "スキルHP回復効果+4%", "回復魔力+20", "みのまもり+15"], ["鼓舞激励の発動率+5%", "最大MP+10", "最大HP+10", "すばやさ+10"]],
      [["ザメハ (仲間全員の眠りをなおす)", "スキルHP回復効果+3%", "回復魔力+30", "みのまもり+15さらに賢者の職業熟練度2以上で+10"], ["鼓舞激励の発動率+5%", "ハッスルダンス (仲間全員のHPを少し回復する)", "みのまもり+10さらにスパスタの職業熟練度2以上で+10", "ちから+20"]],
      [["神官の風障壁 (仲間1人のブレスダメージをかなり軽減する)", "最大HP+15", "最大MP+15", "みのまもり+10"], ["スキル斬撃・体技ダメージ+3%", "ちから+20", "戦闘時のハッスルダンスHP回復効果+10%", "全ての状態異常耐性+5%"]],
      [["まもりのたて (自分への悪い状態変化、異常状態変化を防ぐ)", "回復魔力+15", "最大HP+15さらに賢者の職業熟練度4以上で+15", "回復魔力+15 "], ["アンコール (仲間1人を行動可能にする)", "みのまもり+10", "最大HP+10", "すばやさ+10さらにスパスタの職業熟練度4以上で+15"]],
      [["キアラル (仲間全員の混乱をなおす)", "スキルHP回復効果+3%", "回復魔力+30", "魔力の暴走率+1%"], ["鼓舞激励の発動率+5%", "スキル斬撃・体技ダメージ+2%", "戦闘時のハッスルダンスHP回復効果+10%", "最大HP+10"]],
      [["さとす (仲間1人の魅了をなおす)", "回復魔力+15さらに賢者の職業熟練度6以上で+10", "最大HP+15", "3%の確率で自分が受けた呪文を敵に跳ね返す"], ["すばやさ+5", "最大MP+10さらにスパスタの職業熟練度6以上で+15", "戦闘時のハッスルダンスHP回復効果+5%", "悪い状態変化耐性+5%"]],
      [["呪文HP回復効果+2%", "最大HP+15", "最大MP+15さらに賢者の職業熟練度8以上で+15", "回復魔力+5"], ["とくぎHP回復効果+2%", "みのまもり+10", "最大MP+15さらにスパスタの職業熟練度8以上で+15", "回復魔力+5"]]
    ],
    [
      [["きようさ+15", "最大HP+15", "みのまもり+15", "すばやさ+15"], ["", "", "", ""]],
      [["影縫の発動率+4%", "きようさ+15", "最大MP+10", "すばやさ+10"], ["威圧・強の発動率+10%", "最大HP+20", "ちから+10", "最大MP+15"]],
      [["空蝉の術 (自分のみかわし率と攻撃力を上げる)", "きようさ+15", "すばやさ+20さらにレンジャーの職業熟練度2以上で+15", "全ての状態異常成功率+10%"], ["鬼殺し (自分の斬撃体技ダメージをかなり上げる)", "みのまもり+20", "最大HP+20さらに海賊の職業熟練度2以上で+15", "悪い状態変化耐性+1%"]],
      [["影縫の発動率+3%", "ちから+5", "最大HP+5", "すばやさ+10"], ["威圧・強の発動率+10%", "ちから+15", "スキル斬撃・体技ダメージ+2%", "最大MP+15"]],
      [["影縫いの効果を1ターンにする (本来は1回行動休み)", "すばやさ+10", "最大MP+10さらにレンジャーの職業熟練度4以上で+15", "きようさ+15"], ["大海の浪漫 (威圧の段階に応じてスキルの斬撃体技・ブレスダメージが上昇、攻撃後全ての威圧を解除)", "威圧・強の発動率+15%", "スキル斬撃体技ダメージ+3%", "ちから+15さらに海賊の職業熟練度4以上で+10"]],
      [["フバーハ (仲間全員のブレスダメージを軽減する)", "ブレスダメージ+1%", "すばやさ+15", "きようさ+15さらにレンジャーの職業熟練度6以上で+15"], ["だいぼうぎょ (受けるダメージを90%軽減する)", "威圧状態の相手へのダメージ+5%", "最大HP+20", "すばやさ+20"]],
      [["影縫の発動率+3%", "最大MP+10", "混乱耐性+3%", "みかわし率+1%"], ["威圧・強の発動率+10%", "みのまもり+20さらに海賊の職業熟練度6以上で+10", "ちから+15", "すばやさ+20"]],
      [["ブレスダメージ+1%", "きようさ+15", "すばやさ+15さらにレンジャーの職業熟練度8以上で+15", "最大MP+10"], ["スキル属性斬撃・体技ダメージ+3%", "最大HP+15", "最大MP+15さらに海賊の職業熟練度8以上で+15", "ちから+5"]]
    ],
    [
      [["ちから+15", "攻撃魔力+15", "最大HP+10", "みのまもり+10"], ["", "", "", ""]],
      [["斬撃・体技耐性-5%", "フォースチャージの発動率+4%", "フォースチャージの発動率+4% ", "最大MP+10さらに魔法戦士の職業熟練度2以上で+15"], ["呪文耐性-5%", "因果の発動率+7%", "因果の発動率+8%", "すばやさ+10さらにバトマスの職業熟練度2以上で+15"]],
      [["魔力かくせい", "呪文ダメージ+2%", "攻撃魔力+15", "封印耐性+5%"], ["すてみ", "スキル斬撃・体技ダメージ+2%", "ちから+15", "幻惑耐性+5%"]],
      [["ちから+10", "最大HP+10", "攻撃魔力+15さらに魔法戦士の職業熟練度4以上で+10", "麻痺耐性+3%"], ["攻撃魔力+10", "きようさ+20", "ちから+15さらにバトマスの職業熟練度4以上で+10", "攻撃減耐性+3%"]],
      [["斬撃・体技耐性-5%", "フォースブラスト", "フォースチャージの発動率+10%", "最大MP+10"], ["呪文耐性-5%", "因果の極み", "因果の発動率+12%", "すばやさ+10"]],
      [["斬撃・体技耐性-10%さらに魔法戦士の職業熟練度6以上で+2%", "フォースチャージの発動率+5%", "フォースチャージの発動率+5% ", "全属性ダメージ+3%"], ["呪文耐性-10%さらにバトマスの職業熟練度6以上で+2%", "因果の発動率+7%", "因果の発動率+8%", "2%で受けたダメージの100%を相手にも与える"]],
      [["呪文ダメージ+3%", "攻撃魔力+15", "最大HP+10", "魔力の暴走率+1%"], ["スキル斬撃・体技ダメージ+3%", "ちから+15", "きようさ+20", "会心率+1%"]],
      [["デイン属性呪文ダメージ+3%", "メラ属性呪文ダメージ+3%", "最大MP+15さらに魔法戦士の職業熟練度8以上で+15", "攻撃魔力+5"], ["バギ属性斬撃・体技ダメージ+3%", "イオ属性斬撃・体技ダメージ+3%", "最大HP+15さらにバトマスの職業熟練度8以上で+15", "ちから+5"]]
    ],
    [
      [["最大HP+10", "みのまもり+10", "回復魔力+5", "全属性耐性+1%"], ["", "", "", ""]],
      [["グレイトウォールの発動率+10%", "ちから+5", "みのまもり+15 ", "最大HP+10"], ["スキルHP回復効果+2%", "回復魔力+5", "最大MP+20", "すばやさ+10"]],
      [["マルチガード", "最大MP+10", "ちから+15", "すばやさ+5"], ["ベホイミ", "最大HP+10", "すばやさ+15", "みのまもり+5"]],
      [["グレイトウォールのダメージ軽減率+5%", "ちから+10", "みのまもり+10", "斬撃・体技耐性+1%"], ["スキルHP回復効果+1%", "みのまもり+20", "ちから+10", "ブレス耐性+1%"]],
      [["グレイトウォール発動時自分がHP1で生き残る", "すばやさ+10", "最大HP+30", "スキル斬撃体技ダメージ+2%"], ["スキルHP回復効果+1%", "みのまもり+20", "ちから+10", "ブレス耐性+1%"]],
      [["グレイトウォール発動率+10%", "最大MP+10", "ちから+10 ", "ガード率+1%"], ["スキルHP回復効果+1%", "最大HP+15", "すばやさ+15", "全属性耐性+1%"]],
      [["グレイトウォールのダメージ軽減率+5%", "すばやさ+10", "最大HP+35 ", "斬撃・体技耐性+1%"], ["自分が受けるスキル・どうぐのHP回復効果+1%", "みのまもり+10", "ちから+25", "すべての状態異常耐性+1%"]],
      [["グレイトウォール発動率+10%", "最大HP+20", "みのまもり+20", "スキル斬撃・体技ダメージ+2%"], ["スキルHP回復効果+1%", "回復魔力+10", "最大HP+25", "悪い状態変化耐性+1%"]]
    ],
  ];
  public static conditions = [
    [
      [["コスト<50","コスト<30","ちから<5","スライム系"],[]],
      [["ちから>5","コスト<60","とてもよく","最大HP<10"],["みのまもり>5","コスト<80","とてもよく","ちから<25"]],
      [["回復魔力>30","コスト>20","よく","最大HP>70"],["攻撃魔力>30","コスト>20","よく","すばやさ>60"]],
      [["すばやさ>20","コスト<60","ときどき","すばやさ<65"],["最大HP>10","コスト<80","ときどき","きようさ<65"]],
      [["最大HP>40","コスト>60","名前:キング","すばやさ>75"],["最大MP>30","コスト>50","名前:メタル","最大HP>80"]],
      [["コスト<125","コスト>50","ドラゴン系","攻撃魔力>60"],["コスト>10","コスト<50","回復魔力<35","怪人系"]],
      [["最大HP>100","コスト>120","物質系","最大MP<90"],["最大HP>100","コスト>120","？？？？系","すばやさ>130"]],
      [["スライム系","コスト<30","最大HP>20","回復魔力>20"],["コスト>50","すばやさ<60","悪魔系","あまり"]]
    ],
    [
      [["コスト<35","すばやさ<50","攻撃魔力<30","鳥系"],[]],
      [["攻撃魔力>5","コスト<60","とてもよく","最大MP<15"],["回復魔力>10","コスト<60","とてもよく","ちから<20"]],
      [["最大HP>30","コスト>20","よく","最大HP<75"],["最大HP>50","コスト>20","よく","きようさ<30"]],
      [["ちから<100","コスト>70","ときどき","すばやさ<70"],["最大HP<90","コスト>70","ときどき","きようさ<100"]],
      [["攻撃魔力<50","コスト>80","名前:ダーク","きようさ>30"],["最大MP>30","コスト>50","名前:ロード","最大HP>80"]],
      [["コスト<50","コスト>15","植物系","回復魔力>10"],["コスト>125","すばやさ<100","悪魔系","ちから>100"]],
      [["最大MP>70","？？？？系","コスト>120","すばやさ>120"],["みのまもり>20","スライム系","コスト<35","ちから>10"]],
      [["みのまもり>15","最大HP>30","けもの系","あまり"],["コスト>60","最大MP>50","植物系","ちから>30"]]
    ],
    [
      [["コスト<60","コスト<30","最大MP<20","悪魔系"],[]],
      [["コスト<100","すばやさ<20","とてもよく","最大HP<60"],["コスト<100","ちから>40","とてもよく","最大MP<25"]],
      [["コスト<80","ちから<90","よく","きようさ>30"],["コスト>50","すばやさ<30","よく","ちから>40"]],
      [["コスト>90","最大HP>110","ときどき","みのまもり<110"],["コスト<50","すばやさ<20","ときどき","最大HP<40"]],
      [["コスト<80","ちから>25","水系","最大HP>70"],["コスト>80","ちから<15","けもの系","みのまもり<22"]],
      [["コスト<80","回復魔力<65","最大HP>70","最大MP>80"],["コスト<60","最大HP>40","すばやさ>50","みのまもり>30"]],
      [["コスト>120","すばやさ<85","最大MP>120","回復魔力<40"],["コスト<35","最大HP>40","最大MP>40","ドラゴン系"]],
      [["コスト>50","すばやさ>50","きようさ>50","物質系"],["コスト>30","最大HP>40","けもの系","あまり"]]
    ],
    [
      [["コスト<60","コスト<30","回復魔力>9","けもの系"],[]],
      [["コスト>15","コスト<30","とてもよく","攻撃魔力<20"],["コスト>5","コスト<35","とてもよく","回復魔力>15"]],
      [["コスト>90","きようさ<90","よく","すばやさ>70"],["コスト<50","きようさ>15","よく","最大HP<50"]],
      [["コスト>60","最大MP>60","ときどき","スライム系"],["コスト>60","最大HP>100","ときどき","怪人系"]],
      [["攻撃魔力<25","みのまもり>10","名前:かげ","コスト<60"],["コスト>60","最大HP>50","名前:シャドー","回復魔力<20"]],
      [["最大HP>15","ちから>15","虫系","コスト<20"],["きようさ<60","攻撃魔力>17","ゾンビ系","回復魔力<19"]],
      [["物質系","ちから<100","最大MP<20","きようさ>55"],["コスト<40","悪魔系","最大MP>45","攻撃魔力<50"]],
      [["コスト>100","攻撃魔力>100","悪魔系","すばやさ>120"],["コスト>80","最大HP>80","みのまもり>70","スライム系"]]
    ],
    [
      [["コスト<60","コスト>30","みのまもり<20","ゾンビ系"],[]],
      [["コスト>45","コスト<90","とてもよく","最大HP>55"],["コスト>40","コスト<70","とてもよく","攻撃魔力>15"]],
      [["コスト<70","ちから>20","よく","最大MP>20"],["コスト>45","すばやさ>50","よく","きようさ<70"]],
      [["コスト<100","最大HP>60","ときどき","攻撃魔力<15"],["コスト>50","攻撃魔力<80","ときどき","悪魔系"]],
      [["コスト<90","回復魔力<20","名前:ヘル","きようさ<40"],["すばやさ>30","みのまもり>20","名前:デビル","コスト>80"]],
      [["みのまもり<30","攻撃魔力>35","物質系","最大HP>40"],["コスト>50","みのまもり<80","マシン系","ちから>70"]],
      [["きようさ>50","コスト>100","あまり","ちから>90"],["コスト>100","すばやさ>30","あまり","最大MP>60"]],
      [["回復魔力>30","最大MP>30","コスト>130","ドラゴン系"],["攻撃魔力>15","最大MP>30","鳥系","コスト>80"]]
    ],
    [
      [["コスト<60","最大HP>45","攻撃魔力<20","怪人系"],[]],
      [["コスト>60","ちから<70","とてもよく","最大MP>25"],["コスト>60","ちから>20","とてもよく","回復魔力>10"]],
      [["コスト<90","きようさ>25","よく","すばやさ<50"],["コスト<45","すばやさ<50","よく","最大MP>10"]],
      [["コスト>20","回復魔力>16","みのまもり>10","すばやさ<10"],["コスト>60","最大HP>90","ときどき","みのまもり>65"]],
      [["ちから<45","最大HP<90","名前:ナイト","回復魔力<60"],["最大HP>80","回復魔力>30","名前:王","すばやさ>70"]],
      [["コスト>40","みのまもり<30","ゾンビ系","きようさ<10"],["コスト>40","最大HP>30","エレメント系","すばやさ>85"]],
      [["コスト>60","すばやさ>40","みのまもり>65","攻撃魔力>115"],["コスト>25","攻撃魔力>10","回復魔力<45","みのまもり<10"]],
      [["","","",""],["","","",""]]
    ],
  ]

  public static heroDataList: HeroData[] = [
    {
      page: "Top",
      headingHtml: '<h1 class="PageHero__handingEn">ドラクエウォーク</h1>' + "<b><span>こころ道</span></b>" + "<span>まとめ！</span>",
      descHtml: "特級職のこころ道をまとめてあります。<br/>スキルやモンスター名からこころを検索できます。" +
                "<br/>仲間モンスターの継承玉も検索できます。<br/>麻雀シミュレーターやプレイコインの記録が行えるツールもあります。ぜひご利用ください",
      cls: "-home",
    },
    {
      page: "HeartLoadList",
      headingHtml: "こころ道まとめ一覧",
      descHtml: "完全一致と3つの条件一致があります",
      cls: "-home",
    },
    {
      page: "HeartSearchList",
      headingHtml: "こころの検索",
      descHtml: "",
      cls: "-home",
      child: [
        {
          page: "HeartsRanking",
          headingHtml: "こころ性能ランキング",
          descHtml: "こころのステータスランキングTOP3を確認できます。",
          cls: "-home",
        },
        {
          page: "HeartsSearch",
          headingHtml: "ステータスで検索",
          descHtml: "こころをステータスから検索できます。",
          cls: "-home",
        },
        {
          page: "NameSearch",
          headingHtml: "名前とスキルで検索",
          descHtml: "名前や所持スキルからこころを検索できます。",
          cls: "-home",
        },
      ]      
    },
    {
      page: "FriendlyMonster",
      headingHtml: "仲間モンスター",
      descHtml: "",
      cls: "-home",
      child: [
        {
          page: "FriendlyMonster",
          headingHtml: "継承玉検索",
          descHtml: "条件を絞って継承玉が抽出できるモンスターを検索できます。",
          cls: "-home",
        },
        {
          page: "FriendlyMonsterResist",
          headingHtml: "モンスターの耐性",
          descHtml: "仲間モンスターの耐性一覧です。",
          cls: "-home",
        },
      ]
    },
    {
      page: "NotFound",
      headingHtml: "NotFound",
      descHtml: "NotFound",
      cls: "-none",
    },
  ];
  public static sortUi: infoData[] = [
    {
      title: "ステータス",
      contents: [
        "最大HP",	"最大MP",	"攻撃力",	"守備力",	"素早さ",	"攻撃魔力",	"回復魔力", "器用さ",
      ]
    },
    {
      title: "属性",
      contents: [
        "メラ", "ギラ", "ヒャド", "バギ", "デイン", "ジバリア", "イオ", "ドルマ",
      ]
    },
    {
      title: "系統",
      contents: [
        "スライム", "けもの", "ドラゴン", "虫", "鳥", "植物", "物質", "マシン", "ゾンビ", "悪魔", "エレメント", "怪人", "水", "？？？？",
      ]
    },
    {
      title: "状態異常",
      contents: [
        "毒", "麻痺", "眠り", "混乱", "封印", "幻惑", "呪い", "魅了", "怯え", "休み", "踊り",
      ]
    },
    {
      title: "状態変化",
      contents: [
        "守備減", "攻撃減", "すばやさ減", "全状態異常"
      ]
    },
    {
      title: "その他",
      contents: [
        "即死", "吸収",
      ]
    },
    {
      title: "対象",
      contents: [
        "斬体", "呪文", "ブレス", "全属性", "耐属性", "成功率", "ターン回復"
      ]
    },
    {
      title: "回復",
      contents: [
        "スキル", "呪文HP", "とくぎ",
      ]
    },
    {
      title: "確率",
      contents: [
        "会心", "みかわし", "暴走",
      ]
    },
  ]
  public static infoDataList: infoData[] = [
    {
      title: "こころ道で注意すること",
      contents: [
        "グレードSでないと装備できない",
        "同じこころは使用できない",
        "装備したこころの特殊効果は反映されない",
      ]
    },
    {
      title: "７の付く日はスロット！月末は麻雀！",
      contents: [
        "６枚台のスロットのみ",
        "200ゲームまで",
        "麻雀は特定の役で上がろう",
      ]
    },
    {
      title: "装備忘れに注意！",
      contents: [
        "こころ：メガゴールドマンとタイタス",
        "アクセサリー：ゴールドマンピアスときせきのイヤリング",
        "真珠：黄金島の心珠",
        "頭装備：エンゼルスライム帽",
      ]
    },
  ];
  public static toolList: infoData[] = [
    {
      title: "麻雀シミュレーター",
      contents: [
                  "麻雀の牌を並べてどのような役が出来上がるか試せます。麻雀が全くわからない人はシミュレーションしてみてください。",
                ]
    },
    {
      title: "プレイコインセーブマップ",
      contents: [
                  "グーグルマップを使用してプレイコインを取得した経路を記録できます。",
                  "記録した経路は毎週月曜にリセットされます。ご利用いただくには、google、twitter、facebookいずれかのアカウントが必要です。",
                  "個人情報の取扱についてはプライバシーポリシーをご覧ください。",
                ]
    },
  ];
  public static privacyList: infoData[] = [
    {
      title: "<h2>プライバシーポリシー</h2>",
      contents: [
                  "<p>当ブログでは、どのような個人情報を取得し、どのように利用するかを以下に定めます。</p>",
                ]
    },
    {
      title: "掲示板への個人情報の書き込みについて",
      contents: [
                  "<p>当ブログが提供するサービスにはユーザーが書き込むことができる掲示板を提供しています。</p>",
                  "<p>掲示板への書き込みによりユーザーが自ら開示した個人情報は、他の利用者によっても閲覧可能となりますので、十分にご注意ください。</p>",
                  "<p>コメントした際に開示される個人情報は以下に示します。</P>",
                  "<li>ログインした際のアカウント名</li>",
                  "<li>ログインした際のサムネイル</li>",
                  "<li>投稿したコメント</li>",
                ]
    },
    {
      title: "ログインした際の個人情報の取得と利用目的",
      contents: [
                  "<p>当ブログのプレイコインセーブマップ(以下ツール)は GoogleMap を使用します。ユーザーの同意を得た上で以下の情報を取得します。</p>",
                  "<table border=1><tr><th>取得情報</th><td>位置情報</td></tr><tr><th>利用目的</th><td>GoogleMap の表示をユーザーの現在位置に合わせるため</td></tr></table>",
                  "<p>また、このツールは GoogleMap に情報を書き込むことができます。</p>",
                  "<p>ログインをすることで、書き込んだ情報を保存するデータベースをユーザーごとに作成します。</p>",
                  "ログインした際に取得する個人情報と利用目的を以下に示します。",
                  "<table border=1><tr><th>ログイン方法</th><td>Twitter、Facebook</td><td>Google</td></tr>" +
                                  "<tr><th>取得情報</th><td>アカウント名、サムネイル</td><td>アカウント名、サムネイル、メールアドレス</td></tr>" +
                                  "<tr><th>利用目的</th><td colspan=2>ユーザーごとにデータベースを作成および参照するため</td></tr></table>",
                ]
    },
    {
      title: "ログインした際の個人情報の保存期間について",
      contents: [
                  "<p>位置情報は同意した時、一度のみ取得し、ページを離れると破棄します。</p>",
                  "<p>ログインすると取得した個人情報を元にユーザーを識別するIDを作成し、そのIDからデータベースを作成します。そのため、個人情報そのものはデータベースに使用されません。</p>",
                  "<p>ログインした際に取得した個人情報はページを離れると破棄されます。</p>",
                ]
    },
    {
      title: "お問い合わせによる個人情報の取扱について",
      contents: [
                  "当ブログでは、お問い合わせをする際に名前やメールアドレス等の個人情報をご入力してもらう場合がございます。",
                  "これらの個人情報はご質問に対する回答や、必要な情報をご連絡する時に利用します。これらの目的以外では利用いたしません。",
                ]
    },
    {
      title: "お問い合わせによる個人情報の保存期間について",
      contents: [
                  "当ブログでは、お問い合わせフォームに GoogleForm を使用しており、ご入力していただいた個人情報は GoogleForm に保存されます。",
                  "利用目的達成後には速やかに消去、破棄します。",
                ]
    },
    {
      title: "個人情報の第三者への開示について",
      contents: [
                  "当ブログでは、個人情報を適切に管理し、本人の同意なしに第三者に提供することはありません。",
                ]
    },
    {
      title: "Cookieによる個人情報の取得",
      contents: [
                  "Cookie とは、ユーザーがアクセスしたサイトによって作成されるファイルのことです。このファイルをユーザーの端末内に保存し、次回訪問時に使用することで利便性を高めることができます。",
                  "当ブログでは第三者配信の広告サービスを利用します。このサービスはユーザーの興味ある広告を表示するために Cookie から情報を取得する場合があります。",
                  "Cookeiを無効化する方法はお使いのブラウザによって異なります。一般的に普及しているブラウザについての情報と、Cookieの無効化する方法については、以下をご参照ください。",
                  "<a href='https://support.google.com/chrome/answer/95647?hl=ja&co=GENIE.Platform%3DDesktop' target='_blank'>Google Crome</a>",
                  "<a href='https://support.apple.com/ja-jp/HT201265' target='_blank'>Apple Safari</a>",
                  "また、Cookie を無効にした場合、一部のウェブサイトの機能が使用できなくなったり、一部のページが正しく表示されなくなる場合がありますのでご留意ください。",
                ]
    },
    {
      title: "Cookieの有効期間と削除方法",
      contents: [
                  "Cookie がユーザーの端末内に保存される期間は一時的と永続的があり、一時的なCookie は、セッションCookie と呼ばれブラウザを閉じると破棄されます。",
                  "永続的はユーザーがブラウザを閉じた後でも存在し、再びブラウザを開いてインターネットを閲覧した時 WEB サイトがユーザーのブラウザまたは端末を認識します。",
                  "Cookei を削除する方法はお使いのブラウザによって異なります。一般的に普及しているブラウザについての情報と、Cookie の削除方法については、以下をご参照ください。",
                  "<a href='https://support.google.com/chrome/answer/95647?hl=ja&co=GENIE.Platform%3DDesktop' target='_blank'>Google Crome</a>",
                  "<a href='https://support.apple.com/ja-jp/HT201265' target='_blank'>Apple Safari</a>",
                  "<a href='https://support.microsoft.com/ja-jp/microsoft-edge/microsoft-edge-で-cookie-を削除する-63947406-40ac-c3b8-57b9-2a946a29ae09' target='_blank'>Microsoft Edge</a>",
                  "ただし、Cookie を削除した場合、ウェブサイトからログアウトすることになり、保存されている利用設定は削除される可能性がありますのでご留意ください。",
                ]
    },
    {
      title: "第三者配信の広告サービスについて",
      contents: [
                 "当ブログでは、第三者配信の広告サービス（Googleアドセンス）を利用しています。",
                 "このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当ブログや他サイトへのアクセスに関する情報を Cookie から使用することがあります。",
                 "また Googleアドセンス に関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については、<a href='https://policies.google.com/technologies/ads?hl=ja' target='_blank'>こちら</a>をご確認ください。",
                ]
    },
    {
      title: "アクセス解析ツールについて",
      contents: [
                  "当ブログでは、サイトの利用状況を把握するために Google Analytics を利用しています。 Google Analytics は,トラフィックデータの収集のために Cookie を使用しています",
                  "このトラフィックデータは匿名で収集されており、個人を特定するものではありません。",
                  "この機能は Cookie を無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。",
                  "この規約に関して、詳しくは<a href='https://marketingplatform.google.com/about/analytics/terms/jp/' target='_blank'>こちら</a>からご確認ください。",
                ]
    },
    {
      title: "プライバシーポリシーの変更について",
      contents: ["当ブログは、個人情報に関して適用される日本の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直しその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。",]
    },
    {
      title: "免責事項",
      contents: [
                  "当ブログからリンクやバナーなどで移動した他サイトで提供される情報、サービス等について一切の責任を負いません。",
                  "当ブログのコンテンツ・情報について、できる限り正確な情報を掲載するように努めておりますが、誤情報であったり、情報が古くなっている場合があります。",
                  "万が一、当ブログに掲載された内容によって、いかなる損害や損失などが発生しても、当ブログでは一切の責任を負いかねますのでご了承ください。",
                  "また、当ブログでは予告なく掲載されている情報を変更や削除する可能性があります。",
                  "リンク先の商品は当ブログで販売しているわけではございません。購入方法やお問い合わせはリンク先の店舗までご確認ください。商品購入に関するトラブルに関しては一切の責任を負いかねますのでご了承ください。",
                ]
    },
    {
      title: "著作権",
      contents: ["当ブログで掲載している画像の著作権・肖像権等は各権利所有者に帰属します。万が一問題がある場合はお問い合わせよりご連絡ください。"]
    },
    {
      title: "お問い合わせ",
      contents: ["プライバシーポリシーに関するお問い合わせはお問い合わせフォームからご連絡ください。"]
    },
  ];

}