import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dbd63c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("iframe", {
      src: "https://docs.google.com/forms/d/e/1FAIpQLScAqw-IvkL32kg-JeY9BV9dW6uwR6yjhJBhl7FdDucNcES18Q/viewform?embedded=true",
      width: _ctx.width,
      height: "900",
      frameborder: "0",
      marginheight: "0",
      marginwidth: "0"
    }, "読み込んでいます…", 8, _hoisted_1)
  ]))
}