
import { defineComponent, SetupContext } from 'vue';
import { posAgree } from '../loader/loader';

export default defineComponent({
    name: "Agreement",
    setup(props, context: SetupContext) {
      const Nagree = () => {
        console.log("同意しませんでした");
        context.emit("change-agree");
      };
      const Yagree = () => {
        posAgree();
        context.emit("change-agree");
      }
      return { Nagree, Yagree }
    },
})
