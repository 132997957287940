import { NewsData, NewsCategory, NewsCategories, EmptyObject, imgData } from "./app.config";
import store from ".";
import firebase from "firebase/app";
import { gsReference } from "@/firebase/storage";
import axios, { AxiosResponse, AxiosError } from "axios";
import { AppData } from "@/store/app.data";
import { DispatchType } from "@/store/app.config";

export async function getApi() {
  const dbPromise = getDBApi();
  const newsPromise = apiNews();
  const catePromise = apiCate();
  const imgPromise = getImgApi();

  await Promise.all([
    Promise.race([dbPromise, timeout(5000)]),
    Promise.race([newsPromise, timeout(5000)]),
    Promise.race([catePromise, timeout(5000)]),
    Promise.race([imgPromise, timeout(5000)])
  ]).then(() => {
    console.log("準備OK");
  }).catch(() => console.log("PromiseAllError"));
}

function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
async function getDBApi() {
  try {
    const response = await axios.get(AppData.dqwData);
    store.dispatch(DispatchType.SET_DB, response.data);
  } catch(error) {
    console.log("Error occurred while fetching data: ", error);
  }
}
async function getImgApi() {
  try {
    const imgList: imgData[] = [];
    const { items } = await gsReference.listAll();
    const promise = items.map((item: any) => item.getDownloadURL());
    const imgArray = await Promise.all(promise)
      //.catch(error => console.log("Error fetching all download URLs: ", error));
    for (let i = 0; i < imgArray.length; i++) {
      const num = imgArray[i].split("?")[0].split("o/")[1].split(".")[0];
      imgList.push({"num": Number(num), "url": imgArray[i]});
    }
    console.log("strageOk");
    store.dispatch(DispatchType.SET_IMG, imgList);
  } catch(error) {
    console.log(error);
  } 
}
export async function apiNews(array?: NewsData[]) {
  try {
    const { data } = await axios.get(AppData.newsPath);
    sessionStorage.setItem("NewsData", JSON.stringify(data));
    if(array) array.push(...data);
    //data.forEach((ele: NewsData) => array.push(ele));
    console.log("apiNewsOk")
  } catch(error) {
    console.log(error);
  }
}
export async function apiCate(array?: NewsCategories[]) {
  try {
    const { data } = await axios.get(AppData.newsCategoriesPath);
    sessionStorage.setItem("CategoryData", JSON.stringify(data));
    if(array) array.push(...data);
    //data.forEach((ele: NewsCategories) => array.push(ele));
    console.log("apiCateOk")
  } catch(error) {
    console.log(error);
  }
}
export const sortImgDB = (array: imgData[]): imgData[] => {//DB作成
  if (array.length <= 1) return array;
  const pivot = array[Math.floor(array.length / 2)];
  let left: imgData[] = [];
  let right: imgData[] = [];
  const same: imgData[] = [];
  for (const data of array) {
    if (data["num"] > pivot["num"]) {
      right.push(data);
    } else if (data["num"] < pivot["num"]) {
      left.push(data);
    } else {
      same.push(data);
    }
  }
  left = sortImgDB(left);
  right = sortImgDB(right);
  return left.concat(same, right);
}
export const binarySearch = (array: any[], data: any) => {//StoreDBを直接変更
  let left = 0;
  let right = array.length - 1;
  while(left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (array[mid]["num"] === data["num"]) {
      return array[mid]["url"]
    } else if (array[mid]["num"] > data["num"]){
      right = mid - 1;
    } else if (array[mid]["num"] < data["num"]){
      left = mid + 1;
    }
  }
  return null
}
export const quicksort = (array: Record<string, string>[]): Record<string, string>[] => {//昇順、降順
  if (array.length <= 1 ) return array;
  const pivot = array[0]["コスト"];
  let left = [];
  let right = [];
  const same = [];
  
  for (const data of array) {
    if (data["コスト"] > pivot) {
      right.push(data);
    } else if (data["コスト"] < pivot) {
      left.push(data);
    } else {
      same.push(data);
    }
  }
  left = quicksort(left);
  right = quicksort(right);
  
  return left.concat(same, right);
  //[...quicksort(left), ...same, ...quicksorted(right)]
}
export const statusSort = (array: Record<string, string>[], status: string): Record<string, string>[] => {//昇順、降順
  if (array.length <= 1 ) return array;
  const pivot = array[0][status];
  let left = [];
  let right = [];
  const same = [];
  
  for (const data of array) {
    if (data[status] < pivot) {
      right.push(data);
    } else if (data[status] > pivot) {
      left.push(data);
    } else {
      same.push(data);
    }
  }
  left = statusSort(left, status);
  right = statusSort(right, status);
  
  return left.concat(same, right);
}
const colorList = (["red","yellow","blue","purple","green","all"]);
export const colorsort = (array: any[], color: string) => {
  let left = 0;
  let right = array.length - 1;
  let i = 0;
  const colorKind = [];
  for (let j = 0; j < Math.floor(colorList.length / 2); j++) {
    if (colorKind.length) break;//先頭の色
    i = left;
    while (left <= right && i <= right) {
      if(array[i]["色"] === colorList[j]) {
        array[left] = [array[i], array[i] = array[left]][0];
        left ++;
        i ++;
      } else if (array[i]["色"] === colorList[colorList.length - j - 1]) {
         array[right] = [array[i], array[i] = array[right]][0];
         right --;
      } else {
        i ++;
      }
    }
  colorKind.push(left);
  }
  array.splice(colorKind[0], array.length);
}
export const colorSort = (array: any[], color: string) => {
  return array.filter(data => data["色"].split("ｰ").includes(color));
}
export const findObjectByKey = (categories: NewsCategories[], property: keyof NewsCategories, valueToFind: number): NewsCategories | EmptyObject => {
  const foundCategory = categories.find(category => category[property] === valueToFind);
  return foundCategory ? foundCategory : {acf: {color: 'black'}, name: ""};
};

export const getMainCategory = (newsCategories: NewsCategories[], parent: number): NewsCategories[] => {
  const childDataList: NewsCategories[] = [];
  for (const data of newsCategories) {
    if (data.parent === parent) childDataList.push(data);
  }
  return childDataList;

};

export const getMainCategoryData = (newsData: NewsData[], category: number): NewsData[] => {
  const retDataList: NewsData[] = [];
  for (const data of newsData) {
    if (data.categories.length > 1) {
      for (const cate of data.categories) {
        if (cate === category) retDataList.push(data);
      }
    } else if (data.categories.length === 1) {
      if (data.categories[0] === category) retDataList.push(data);
    }
  }
  return retDataList;
};

export const getCategoryNewsData = (newsData: NewsData[], category: number): NewsData[] => {
  return newsData.filter(data =>
    category === NewsCategory.ALL ? true : data.categories?.includes(category)
  );
};

export const getSliceFilteredData = (filterData: NewsData[], carry: number): NewsData[] => {
  const displays = store.getters.displays;
  const startIndex = (carry - 1) * displays;
  const endIndex = startIndex + displays;
  
  return filterData.slice(startIndex, endIndex);
};

export const getPaginationBlocks = (dataLength: number): number => {
  const displays = store.getters.displays;
  const maxBlocks = store.getters.maxBlocks;
  
  return Math.ceil(dataLength / displays) < maxBlocks ? Math.ceil(dataLength / displays) : maxBlocks;
};

export const getDataDateTime = (dateStr: string | null | undefined): string => {
  const date = new Date(dateStr ?? new Date());
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  
};

export const getExpireAt = (): firebase.firestore.Timestamp => {
  const dayList = ["Sun", "Sat", "Fri", "Thu", "Wed", "Tue", "Mon"];
  const dayindex = dayList.indexOf(String(new Date()).split(" ")[0]);
  const hoursToadd = (dayindex !== -1 ? dayindex + 1 : 0) * 24;
  return firebase.firestore.Timestamp.fromMillis(new Date().getTime() + hoursToadd * 1000 * 60 * 60);
}

class TrieNode {
  char?: string
  isCharLast: boolean
  children: Record<string, any>
  data?: any
  constructor(char?: string,isCharLast = false) {
    this.char = char
    this.children = {}
    this.isCharLast = isCharLast
  }
  pointer(word: string, isboolean: boolean, data?: string) {
    if(!this.children[word]) {
      this.children[word] = new TrieNode(word, isboolean) 
    }
    const childNode = this.children[word];
    //isCharLastとdataの上書きを防ぐ
    childNode.isCharLast = childNode.isCharLast || isboolean;
    if (childNode.isCharLast && isboolean) {
      if (!childNode["data"]) childNode["data"] = [data];
      else childNode["data"].push(data);
    } 

    return childNode
  }
}
export class Trie {
  head: TrieNode
  constructor() {
    this.head = new TrieNode()
  }
  addValue(word: string, data: any) {
    const words = [...word];
    let currentNode = this.head;
    for (let i = 0; i < words.length; i++) {
      const isCharLast = i === words.length - 1
      currentNode = currentNode.pointer(words[i], isCharLast, data)
    }
  }
  depth(currentNode: any, words: string) {
    const data:Record<string, string>[] = [];
    for (let i = 1; i < words.length; i++) {
      if(!currentNode.children[words[i]]) return [];
      currentNode = currentNode.children[words[i]];
    }
    if(currentNode?.isCharLast) {
      data.push(currentNode.data);
    }
    const callback = (children: Record<string, any>) => {
      Object.keys(children).forEach((key:any) => {
        if (children[key].isCharLast) data.push(children[key].data);
        //children = children[key].children
        if (!Object.keys(children[key].children))console.log(children[key].children, key)
        callback(children[key].children)
      })
    }
    if (currentNode) callback(currentNode?.children);
    return data;
  }
  wide(word: string) {
    const initials = Array.from(word)[0];
    const currentNode = this.head;
    const data: any[] = []
    const result: any[] = []
    for (let i = 0; i < Object.keys(currentNode.children).length; i++) {
      data.push(currentNode.children[Object.keys(currentNode.children)[i]]);
    }
    const callback = (NodeList: any) => {
      const data: any[] = []
      NodeList.forEach((value:any) => {
        if(value.char === initials) {
          result.push(this.depth(value, word));
        }
        for (let i = 0; i < Object.keys(value.children).length; i++) {
          data.push(value.children[Object.keys(value.children)[i]])
        }
        //data.push(value.children)
      })
      if(data.length === 0) return
      callback(data);
    }
    callback(data);
    return [...new Set(result.flat(Infinity))];
  }
}
function handleMouseWheel(e:any) {
  e.preventDefault();
}
function handleTouchMove(e:any) {
  e.preventDefault();
}
function handleKeyDown(e:any) {
  switch (e.keyCode) {
    case 0x25:
    case 0x26:
    case 0x27:
    case 0x28:
      e.preventDefault();
      break;
  }
}
export const addScrollEvent = () => {
  document.addEventListener("wheel", handleMouseWheel, { passive: false });
  document.addEventListener("touchmove", handleTouchMove, { passive: false });
  document.addEventListener("keydown", handleKeyDown, { passive: false });
}
export const removeScrollEvent = () => {
  document.removeEventListener("wheel", handleMouseWheel );
  document.removeEventListener("touchmove", handleTouchMove);
  document.removeEventListener("keydown", handleKeyDown);
}