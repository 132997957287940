
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        url: {
            type: String,
        },
        width: {
            type: Number,
        },
        height: {
            type: Number,
        },
    },
    setup(props) {
        var imgSize = ref<boolean>(false);
        if (props.width && props.height && props.width / props.height >= 1.1) {
            imgSize.value = true;
        }else{ 
            imgSize.value = false;
        }
        return { imgSize }
    },
})
