
import { AppData } from '@/store/app.data';
import { defineComponent, ref } from 'vue';
import ArrowLeft from '../slider/ArrowLeft.vue';
import ArrowRight from '../slider/ArrowRight.vue';

export default defineComponent({
  components: { ArrowLeft, ArrowRight },
    setup() {
      const infoList = AppData.infoDataList;
      var nowCarouselNum = ref(Math.floor(Math.random() * infoList.length));
      const onClickNext = () => {
        nowCarouselNum.value ++;
        if (infoList.length <= nowCarouselNum.value) nowCarouselNum.value = 0;
      }
      const onClickPrev = () => {
        nowCarouselNum.value --;
        if (nowCarouselNum.value < 0) nowCarouselNum.value = infoList.length - 1;
      }
      
      return { 
               infoList, onClickNext, onClickPrev, nowCarouselNum 
              }
    },
})
