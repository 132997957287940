
import { defineComponent, ref, reactive, toRefs, isRef } from "vue";
import NewsTab from "@/components/pages/NewsTab.vue";
import DateVue from "./Date.vue";
import PageBreadcrumbs from "@/components/pages/PageBreadcrumbs.vue";
import store from "@/store";
import { DispatchType, NewsCategories, NewsData } from "@/store/app.config";
import ProfileData from "./ProfileData.vue";
import SearchArticle from "@/components/sub/SearchArticle.vue";
import { apiNews, apiCate } from "@/store/app.utils";
import ToolsList from "./ToolsList.vue";
import router from "@/router";

import RHeartRoadTest from "@/components/pages/RHeartRoadTest.vue";
export default defineComponent({
  name: "Top",
  setup() {
    store.dispatch(DispatchType.SET_PAGE, "Top");
    store.dispatch(DispatchType.SET_PAGEKIND, "");
    const newsData = ref<NewsData[]>([]);
    const newsCategories = ref<NewsCategories[]>([]);
    let NewsFlag = ref<boolean>(false);
    let CateFlag = ref<boolean>(false);
    
    (async () => {
      try {
        await Promise.all([apiNews(newsData.value), apiCate(newsCategories.value)]);
        NewsFlag.value = true;
        CateFlag.value = true;
      } catch (error) {
        console.error(error);
      }
    })();
    return { newsData, newsCategories, NewsFlag, CateFlag };
  },

  components: {
    NewsTab,
    DateVue,
    PageBreadcrumbs,
    //RecentNews,
    ProfileData,
    SearchArticle,
    ToolsList,
    //RHeartRoadTest
},
});
