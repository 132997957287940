
import { defineComponent, ref, computed } from 'vue';
import { AppData } from '@/store/app.data';
import store from '@/store';
import { DispatchType } from '@/store/app.config';
import router from '@/router';

export default defineComponent({
    name: "ToolsList",
    setup() {
    let jobList: string[] = AppData.jobList;
    let eyecahchwidth = ref<string>("");
    let subDivision = ref<number>(store.getters.subDivision);
    const heroDataList = AppData.heroDataList.slice(1, -1);
    var judge = ref<boolean[]>([]);
    const isModalOpen = computed(() => store.getters.isModalOpen);
    const cId = computed(() => store.getters.modalId);
    const ModalOpen = (id: number) => {
      store.dispatch(DispatchType.MODAL_OPEN);
      store.dispatch(DispatchType.SET_MODAL, id);
    };
    let sub = ref("message");
    const GoToHeart= (index: number) => {
      store.dispatch(DispatchType.SET_PAGEKIND, jobList[index]);
      router.push({ name: 'RHeartRoadTest', params: { id: index.toString() } });
    };
    const HeartDetail = (url: string) => {
      router.push({ name: url});
    };
    return { 
             isModalOpen, ModalOpen, cId,
             subDivision, sub, eyecahchwidth, judge, GoToHeart, jobList,
             heroDataList, HeartDetail,
           };
  },
})
