
import { defineComponent,PropType, ref, SetupContext } from "@vue/runtime-core";
import store from "@/store";
import { DispatchType } from "@/store/app.config";
import DetailOfHeartResult from "./DetailOfHeartResult.vue";
import CloseButton from "../button/CloseButton.vue";
import ModalOverlay from "./ModalOverlay.vue";

export default defineComponent({
  name: "DetailOfHeart",
  props: {
    data: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  emits: ["changeModal"],
  setup(_, context: SetupContext) {
    const onOpenMenu = ref<boolean>(false);
    const onCloseModal = () => {
      store.dispatch(DispatchType.SET_PAGE, String(0));
      context.emit("changeModal");
    };
    return { onCloseModal, onOpenMenu };
  },
  components: {
    DetailOfHeartResult,
    CloseButton,
    ModalOverlay,
  },
});
