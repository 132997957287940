
import CloseButton from '@/components/button/CloseButton.vue';
import ModalOverlay from '@/components/pages/ModalOverlay.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: "SortUi",
    components: { ModalOverlay,  },//CloseButton
    props: {
        buttonName: {
            type: String,
            default: "ソート",
        },
    },
    setup() {
        let fadeUp = ref(false);
        const openSort = ref(false);
        const openSortUi = () => {
            openSort.value = true;
            setTimeout(() => {
                fadeUp.value = true;
            },100);
        }
        const closeSortUi = () => {
            openSort.value = !openSort.value;
            fadeUp.value = false;
        }
        return { fadeUp, openSort, openSortUi, closeSortUi }
    },
})
