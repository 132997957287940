
import { defineComponent } from 'vue';
import { NewsData } from '@/store/app.config';
import store from '@/store';
import { DispatchType } from '@/store/app.config';
import router from '@/router';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: "RecentNews",
    setup() {
      const route = useRoute();
      let newsdata = sessionStorage.getItem("NewsData");
      if(newsdata) {
        var RecentNewsData = JSON.parse(newsdata).slice(0, 5);
      }
      
      const NewsClick = (newsdata: NewsData) => {
        store.dispatch(DispatchType.SET_PAGE, window.pageYOffset);
        store.dispatch(DispatchType.MODAL_MENU, newsdata);
        store.dispatch(DispatchType.SET_PAGE, "News");
        store.dispatch(DispatchType.SET_PAGEY, window.scrollY);
        route.path === "/News" ? location.reload() : router.push({ name: "News" });
      };
      
      
      return { RecentNewsData, NewsClick }
    },
    
})
