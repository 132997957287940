import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52ea6514"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "PageBreadcrumbs",
  "aria-label": "パンくずリスト"
}
const _hoisted_2 = {
  class: "PageBreadcrumbs__list",
  itemscope: "",
  itemtype: "https://schema.org/BreadcrumbList"
}
const _hoisted_3 = { itemprop: "name" }
const _hoisted_4 = {
  key: 1,
  itemprop: "item"
}
const _hoisted_5 = { itemprop: "name" }
const _hoisted_6 = ["content"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paths, (path, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: path,
          class: "PageBreadcrumbs__item",
          itemprop: "itemListelement",
          itemscope: "",
          itemtype: "https://schema.org/ListItem"
        }, [
          (index !== _ctx.names.length - 1)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "PageBreadcrumbs__link",
                itemprop: "item",
                to: path
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.names[index]), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.names[index]), 1)
              ])),
          _createElementVNode("meta", {
            itemprop: "position",
            content: String(index + 1)
          }, null, 8, _hoisted_6)
        ]))
      }), 128))
    ])
  ]))
}