import { onMounted, onUnmounted, reactive, toRefs } from "vue";

export default (atResize?: () => void): any => {
  const state = reactive({
    ww: window.innerWidth,
    wh: window.innerHeight,
    dw: document.documentElement.clientWidth,
    dh: document.documentElement.clientHeight,
  });
  const onResize = () => {
    state.ww = window.innerWidth;
    state.wh = window.innerHeight;
    state.dw = document.documentElement.clientWidth;
    state.dh = document.documentElement.clientHeight;
    if (atResize) {
      atResize();
    }
  };
  onMounted(() => window.addEventListener("resize", onResize));
  onUnmounted(() => window.removeEventListener("resize", onResize));

  return {
    ...toRefs(state),
  };
};
