import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b6c84b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "News" }
const _hoisted_2 = { class: "Contents" }
const _hoisted_3 = { class: "Contents__news" }
const _hoisted_4 = { class: "Contents__layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageBreadcrumbs = _resolveComponent("PageBreadcrumbs")!
  const _component_tools_list = _resolveComponent("tools-list")!
  const _component_NewsTab = _resolveComponent("NewsTab")!
  const _component_SearchArticle = _resolveComponent("SearchArticle")!
  const _component_ProfileData = _resolveComponent("ProfileData")!
  const _component_DateVue = _resolveComponent("DateVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageBreadcrumbs),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_tools_list),
        (_ctx.NewsFlag && _ctx.CateFlag)
          ? (_openBlock(), _createBlock(_component_NewsTab, {
              key: 0,
              "news-data": _ctx.newsData,
              newsCategories: _ctx.newsCategories
            }, null, 8, ["news-data", "newsCategories"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SearchArticle),
        _createVNode(_component_ProfileData),
        _createVNode(_component_DateVue, {
          onChangeData: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newsData = $event))
        })
      ])
    ])
  ]))
}