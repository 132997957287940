
import { defineComponent } from 'vue';
import { AppData } from '@/store/app.data';
import PageBreadcrumbs from '../pages/PageBreadcrumbs.vue';

export default defineComponent({
    setup() {
      const pTitle = AppData.privacyList.slice(0,1);
      const pList = AppData.privacyList.slice(1);
      return { pList, pTitle }
    },
    components: {
      PageBreadcrumbs,
    }
})
