import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EyeCatchingImg = _resolveComponent("EyeCatchingImg")!

  return (_ctx.thumbnail !== '')
    ? (_openBlock(), _createBlock(_component_EyeCatchingImg, {
        key: 0,
        url: _ctx.thumbnail,
        width: _ctx.width,
        height: _ctx.height
      }, null, 8, ["url", "width", "height"]))
    : _createCommentVNode("", true)
}