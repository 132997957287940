import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03b578da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Heart" }
const _hoisted_2 = { class: "Heart__Main" }
const _hoisted_3 = { class: "Heart__" }
const _hoisted_4 = { class: "Heart__Tittle" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "Heart__Transition" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "Heart__Sub" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalOverlay = _resolveComponent("ModalOverlay")!
  const _component_Infomation = _resolveComponent("Infomation")!
  const _component_Indicator = _resolveComponent("Indicator")!
  const _component_PageBreadcrumbs = _resolveComponent("PageBreadcrumbs")!
  const _component_RoadTree = _resolveComponent("RoadTree")!
  const _component_ProfileData = _resolveComponent("ProfileData")!
  const _component_RecentNews = _resolveComponent("RecentNews")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_ctx.nowLoad)
      ? (_openBlock(), _createBlock(_component_ModalOverlay, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.nowLoad)
      ? (_openBlock(), _createBlock(_component_Infomation, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.nowLoad)
      ? (_openBlock(), _createBlock(_component_Indicator, {
          key: 2,
          complete: _ctx.nowLoad
        }, null, 8, ["complete"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageBreadcrumbs, { label: "こころ道" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", { src: _ctx.jobimg }, null, 8, _hoisted_5),
            _createElementVNode("h2", null, _toDisplayString(_ctx.job[Number(_ctx.$props.id)]) + "のこころ道", 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "Heart__Control -prev",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickPagePrev && _ctx.onClickPagePrev(...args)))
            }, [
              (_ctx.job[Number(_ctx.$props.id) - 1])
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.job[Number(_ctx.$props.id) - 1]), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.job[_ctx.job.length - 1]), 1))
            ]),
            _createElementVNode("button", {
              class: "Heart__Control -next",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickPageNext && _ctx.onClickPageNext(...args)))
            }, [
              (_ctx.job[Number(_ctx.$props.id) + 1])
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.job[Number(_ctx.$props.id) + 1]), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.job[0]), 1))
            ])
          ]),
          _createVNode(_component_RoadTree, {
            color: _ctx.color,
            id: _ctx.jobId
          }, null, 8, ["color", "id"])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_ProfileData),
        _createVNode(_component_RecentNews)
      ])
    ])
  ], 64))
}