import { FirebaseApp } from "./app";
import firebase from "firebase/app";
import "firebase/auth";

export const FirebaseAuth: firebase.auth.Auth = FirebaseApp.auth();
firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.NONE)
  .then(() => {
    console.log("Initialized!");
  })
  .catch((error) => {
    console.log(error);
  });
