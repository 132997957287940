
import store from '@/store';
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { colorSort, quicksort, statusSort, binarySearch, getApi, sortImgDB, Trie } from '@/store/app.utils';
import { imgData } from '@/store/app.config';
import useWindowSize from "@/composable/use-window-size";
import DetailOfHeart from '@/components/pages/DetailOfHeart.vue';
import TopButton from '@/components/button/TopButton.vue';
import PageBreadcrumbs from '@/components/pages/PageBreadcrumbs.vue';
import ModalOverlay from '@/components/pages/ModalOverlay.vue';
import Infomation from '@/components/loading/Infomation.vue';
import Indicator from '@/components/loading/Indicator.vue';

export default defineComponent({
  
    setup() {
      var nowLoad = ref(true);
      async function createDB () {
        await getApi();
        nowLoad.value = false;
      }
      createDB();

      const maxResult: maxResultInterface = reactive({
        "最大HP": [],
        "最大MP": [],
        "ちから": [],
        "みのまもり": [],
        "攻撃魔力": [],
        "回復魔力": [],
        "すばやさ": [],
        "きようさ": [],
      })
      interface maxResultInterface  {
        "最大HP": (Record<string, string>|undefined)[],
        "最大MP": (Record<string, string>|undefined)[],
        "ちから": (Record<string, string>|undefined)[],
        "みのまもり": (Record<string, string>|undefined)[],
        "攻撃魔力": (Record<string, string>|undefined)[],
        "回復魔力": (Record<string, string>|undefined)[],
        "すばやさ": (Record<string, string>|undefined)[],
        "きようさ": (Record<string, string>|undefined)[],
      }
      
      const onOpenMenu = ref<boolean>(false);
      const openMenu = () => {
        onOpenMenu.value = true;
      }
      const blist = ref<boolean[][]>(Object.keys(maxResult).map(() => Array(3).fill(false)));//Top3
      const colorButton = ["full","red","yellow","blue","purple","green","all"];
      const detail = (indexs: number, index: number) => {
        blist.value[indexs][index] = !blist.value[indexs][index];
      }
      let dqwdbList = ref<Record<string, string>[]>([])
      const clickcolor = (color: string) => {
        const dqwdb: Record<string, string>[] = store.getters.dbCaching;
        const imgList: imgData[] = store.getters.imgDb;
        const sortData = sortImgDB(imgList);
        for (let i = 0; i < dqwdb.length; i++) {
          dqwdb[i]["url"] = binarySearch(sortData, dqwdb[i]);
        }
        if (color === "full") {
          dqwdbList.value = quicksort(dqwdb);
        } else {
          dqwdbList.value = colorSort(quicksort(dqwdb), color);
        }
        maxResult["最大HP"] = statusSort(dqwdbList.value, "最大HP").slice(0, 3);
        maxResult["最大MP"] = statusSort(dqwdbList.value, "最大MP").slice(0, 3);
        maxResult["ちから"] = statusSort(dqwdbList.value, "ちから").slice(0, 3);
        maxResult["みのまもり"] = statusSort(dqwdbList.value, "みのまもり").slice(0, 3);
        maxResult["攻撃魔力"] = statusSort(dqwdbList.value, "攻撃魔力").slice(0, 3);
        maxResult["回復魔力"] = statusSort(dqwdbList.value, "回復魔力").slice(0, 3);
        maxResult["すばやさ"] = statusSort(dqwdbList.value, "すばやさ").slice(0, 3);
        maxResult["きようさ"] = statusSort(dqwdbList.value, "きようさ").slice(0, 3);
      }

      let wide = ref<boolean>(false);
      const setPos = (dw: number) => {
        if (dw > 768) {
          wide.value = true;
        } else {
          wide.value = false;
        }
      };
      const { dw } = useWindowSize(() => {
        setPos(dw.value);
      });
      setPos(dw.value);
      
      return { dqwdbList, blist, onOpenMenu, detail, 
               openMenu, clickcolor, colorButton, maxResult, wide, nowLoad }
    },
    methods: {
      imgSrc() {
        return require("../assets/no_imag.jpg");
      }
    },
    components: { DetailOfHeart, TopButton, PageBreadcrumbs, ModalOverlay, Infomation, Indicator },
})
