
import { defineComponent, computed } from "vue";
import PageHeroInner from "./pages/PageHeroInner.vue";
import { AppData } from "@/store/app.data";

export default defineComponent({
  name: "PageHero",
  props: {
    nowPage: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const pageList: string[] = [];
    const currentPageIndex = computed(() => pageList.indexOf(props.nowPage)); /* pageList中のTop(props.nowPage)のindexを返す */
    const heroDataList = computed(() => AppData.heroDataList);
    const prevPage = computed(() => {
      return pageList;
    });
    const nextPage = computed(() => {
      return pageList;
    });
    return {
      currentPageIndex,
      heroDataList,
      prevPage,
      nextPage,
    };
  },
  components: {
    PageHeroInner,
  },
});
