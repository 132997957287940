
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import router from "@/router";
import { useRoute } from "vue-router";
import { DispatchType } from "@/store/app.config";
import PrivacyPolicy from "./header/privacyPolicy.vue";

export default defineComponent({
  name: "AppHeader",
  components: {},
  setup() {
    const isMenuOpen = computed(() => store.getters.isMenuOpen);
    const message = ref("");
    const balloon = ref(false);
    const route = useRoute();
    addEventListener('scroll',() => balloon.value = false, {passive: true})
    const onclick = () => {
      store.dispatch(DispatchType.SET_SUB, String(1));
      if (message.value === "") {
        balloon.value = true;
      } else {
        router.push({ name: "Search", query: { search: message.value } });
      }
    };
    const gotoTop = () => {
      if (route.name === "Top") {
        sessionStorage.clear();
        location.reload();
      } else {
        router.push({ name: "Top" });
      }
    };
    return {
      isMenuOpen,
      message,
      onclick,
      gotoTop,
      balloon,
    };
  },
});
