import { createStore } from "vuex";
import { State, DispatchType, ModalId, BasicDisplays, PaginationBlocks } from "./app.config";
import { Page } from "../router/index";
import createPersistedState from "vuex-persistedstate";

export default createStore<State>({
  plugins: [
    createPersistedState({
      paths: ['subDivision', 'nowPage', 'isMenuOpen', 'isModalOpen', 'modalId', 'displays',
              'maxBlocks', 'modalMenu', 'PageY', 'pageKind', 'dbCaching',// 'allMatch', 'threeMatch'
              ],
      key: "SampleApp",
      storage: window.sessionStorage,
    }),
  ],
  state: {
    subDivision: 1,
    nowPage: Page.TOP,
    isMenuOpen: false,
    isModalOpen: false,
    modalId: ModalId.NONE,
    displays: BasicDisplays.DISPLAYS, //項目の表示数
    maxBlocks: PaginationBlocks.BLOCKS, //ページネーションの最大値

    modalMenu: "", //modalで表示する内容
    dbCaching: "",
    imgDb: "",
    allMatch: "",
    threeMatch: "",

    PageY: 0,
    pageKind: "",

    map: [],
    google: [],
    drawingManager: null,
  },
  getters: {
    subDivision: (state) => state.subDivision,
    nowPage: (state) => state.nowPage,
    isMenuOpen: (state) => state.isMenuOpen,
    isModalOpen: (state) => state.isModalOpen,
    modalId: (state) => state.modalId,
    displays: (state) => state.displays,
    maxBlocks: (state) => state.maxBlocks,

    modalMenu: (state) => state.modalMenu,
    dbCaching: (state) => state.dbCaching,
    imgDb: (state) => state.imgDb,
    allMatch: (state) => state.allMatch,
    threeMatch: (state) => state.threeMatch,

    getPageY: (state) => state.PageY,
    pageKind: (state) => state.pageKind,
    
    map: (state) => state.map[0],
    google: (state) => state.google[0],
    drawingManager: (state) => state.drawingManager,
  },
  mutations: {
    setpageKind(state, pageKind) {
      state.pageKind = pageKind;
    },
    setSub(state, newSub) {
      state.subDivision = newSub;
    },
    setPage(state, newPage) {
      if (newPage == "TopNews") newPage = "Top";
      state.nowPage = newPage;
    },
    menuToggle(state) {
      state.isMenuOpen = !state.isMenuOpen;
    },
    menuClose(state) {
      state.isMenuOpen = false;
    },
    setModal(state, modalId) {
      state.modalId = modalId;
    },
    modalOpen(state) {
      state.isModalOpen = true;
    },
    modalClose(state) {
      state.isModalOpen = false;
    },
    displaysChange(state, Pages) {
      state.displays = Pages;
    },

    modalMenu(state, menu) {
      state.modalMenu = menu;
    },
    setImgDb(state, db) {
      state.imgDb = db;
    },
    dbCaching(state, db) {
      state.dbCaching = db;
    },
    allMatch(state, data) {
      state.allMatch = data;
    },
    threeMatch(state, data) {
      state.threeMatch = data;
    },

    setPageY(state, data: number) {
      state.PageY = data;
    },

    setMap(state, map: any) {
      state.map[0] = map;
    },
    setGoogle(state, google: any) {
      state.google[0] = google;
    },
    setDrawingManager(state, drawingManager: any) {
      state.drawingManager = drawingManager;
    }
  },
  actions: {
    setpageKind({ commit }, pageKind) {
      commit(DispatchType.SET_PAGEKIND, pageKind);
    },
    setSub({ commit }, newSub) {
      commit(DispatchType.SET_SUB, newSub);
    },
    setPage({ commit }, newPage) {
      commit(DispatchType.SET_PAGE, newPage);
    },
    menuToggle({ commit }) {
      commit(DispatchType.MENU_TOGGLE);
    },
    menuClose({ commit }) {
      commit(DispatchType.MENU_CLOSE);
    },
    setModal({ commit }, modalId) {
      commit(DispatchType.SET_MODAL, modalId);
    },
    modalOpen({ commit }) {
      commit(DispatchType.MODAL_OPEN);
    },
    modalClose({ commit }) {
      commit(DispatchType.MODAL_CLOSE);
    },
    displaysChange({ commit }, Pages) {
      commit(DispatchType.DISPLAYS_CHANGE, Pages);
    },

    modalMenu({ commit }, menu) {
      commit(DispatchType.MODAL_MENU, menu);
    },
    dbCaching( { commit }, db) {
      commit(DispatchType.SET_DB, db);
    },
    setImgDb( { commit }, db) {
      commit(DispatchType.SET_IMG, db);
    },
    allMatch({ commit }, data) {
      commit(DispatchType.ALL_MATCH, data);
    },
    threeMatch({ commit }, data) {
      commit(DispatchType.THREE_MATCH, data);
    },

    setPageY({ commit }, data) {
      commit(DispatchType.SET_PAGEY, data);
    },

    setMap({ commit }, map: any) {
      commit(DispatchType.SET_MAP, map);
    },
    setGoogle({ commit }, google: any) {
      commit(DispatchType.SET_GOOGLE, google);
    },
    setDrawingManager({ commit }, drawingManager: any) {
      commit(DispatchType.SET_MANAGER, drawingManager);
    }
  },
  modules: {},
});
