export interface State {
  subDivision: number,
  nowPage: string,
  isMenuOpen: boolean,
  isModalOpen: boolean,
  modalId: number,
  displays: number, //項目の表示数
  maxBlocks: number, //ページネーションの最大値
  modalMenu: string,
  
  dbCaching: string,
  imgDb: string,
  allMatch: string,
  threeMatch: string,

  PageY: number,
  pageKind: string,
  
  map: any[];
  google: any[];
  drawingManager: any | null;
}
export interface testList {
  title: string,
  contents: string,
}
export interface infoData {
  title: string,
  contents: string[],
}
export interface imgData {
  num: number;
  url: string;
}
export interface Dictionary {
  [key: string]: number;
}

export interface refDate {
  [key: number]: number[];
}

export interface BnrData {
  src: string;
  href: string;
  alt: string;
  comments: string;
}

export interface PositionData {
  lat: number[];
  lng: number[];
  expireAt: any | null;
}
export interface HeroData {
  page: string;
  headingHtml: string;
  descHtml: string;
  cls: string;
  child?: HeroData[];
}

export interface NewsData {
  id: number;
  title: { rendered: string };
  content: { rendered: string };
  excerpt: { rendered: string };
  categories: number[];
  date: string;
  featured_media: number;
}
export interface NewsCategories {
  id: number;
  name: string;
  slug: string;
  parent: number;
  acf: { color: string };
}
export interface EyeCatching {
  guid: { rendered: string };
  media_details: { width: number, height: number };
}
export interface ArticleNews {
  title: { rendered: string };
  content: { rendered: string };
  date_gmt: string;
  modified: string;
}

export interface TopNewsCategory {
  detailDate: string;
  detailTitle: string;
  detailContent: string;
  detailCategory: number;
  categories: number[];
}

export interface ChapterData {
  category: string;
  title: string;
}

export interface ChapterDetailData {
  title: string;
  detailHtml: string;
  subList?: string[];
}

export interface CarouselAboutData {
  src: string;
  srcL: string;
  alt: string;
}

export interface FaqData {
  question: string;
  answer: string;
}

export interface EmptyObject {
  //[key: string]: never;
  acf: { color: string };
  name: string;
}

export interface UserData {
  loginStatus: boolean;
  displayName?: string | null;
  photoURL?: string | null;
  uid?: string;
}

export interface PopSignatures {//playCoinMap
  pop(this: { length: 0 }): undefined;
  pop(this: { 0: string }): string;
}

export const NewsCategory = {
  ALL: 0,
  MEMO: 5,
  DQW: 3,
} as const;
//woedpressから取得したjsonfileのカテゴリIdと一致させる
export const DispatchType = {
  SET_SUB: "setSub",
  MENU_OPEN: "menuOpen",
  MENU_CLOSE: "menuClose",
  MENU_TOGGLE: "menuToggle",
  MODAL_OPEN: "modalOpen",
  MODAL_CLOSE: "modalClose",
  SET_MODAL: "setModal",
  SET_PAGE: "setPage",
  DISPLAYS_CHANGE: "displaysChange",

  MODAL_MENU: "modalMenu",
  SET_DB: "dbCaching",
  SET_IMG: "setImgDb",
  ALL_MATCH: "allMatch",
  THREE_MATCH: "threeMatch",

  SET_PAGEY: "setPageY",
  SET_PAGEKIND: "setpageKind",

  SET_GOOGLE: "setGoogle",
  SET_MANAGER: "setMap",
  SET_MAP: "setDrawingManager",

} as const;

export const ModalId = {
  NONE: -1,
} as const;

export const BasicDisplays = {
  DISPLAYS: 5,
} as const;

export const PaginationBlocks = {
  BLOCKS: 5,
} as const;
