
import { defineComponent, PropType,ref } from "vue";
import { NewsData, DispatchType, NewsCategories } from "@/store/app.config";
import NewsList from "@/components/pages/NewsList.vue";
import store from "@/store";

export default defineComponent({
  name: "NewsTab",
  props: {
    newsData: {
      type: Object as PropType<NewsData[]>,
      required: true,
    },
    newsCategories: {
      type: Object as PropType<NewsCategories[]>,//?.の関係上any
      required: true,
    },
  },
  setup(props) {
    let selectCategory = ref(0);
    const selectTab:any = (tabName: number) => {
      store.dispatch(DispatchType.SET_SUB, 1);
      if (selectCategory.value !== tabName) selectCategory.value = tabName;
    }
    return { selectCategory, selectTab }
  },
  components: {
    NewsList,
  },
});
