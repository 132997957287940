
import { defineComponent } from "vue";
import PageBanner from "@/components/footer/PageBanner.vue";

export default defineComponent({
  name: "AppFooter",
  setup() {
    const thisYear = new Date().getFullYear();
    return {
      thisYear,
    };
  },
  components: {
    //PageBanner,
  },
});
