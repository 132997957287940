
import { defineComponent, onMounted,onUnmounted, ref, reactive, toRefs } from 'vue';
import store from '@/store';
import { usersRef } from '@/firebase/users';
import { FirebaseAuth } from "@/firebase/auth";
import firebase from "firebase/app";
import { UserData, PositionData } from "@/store/app.config";
import { getExpireAt } from '@/store/app.utils';
import Agreement from '../loading/Agreement.vue';
import ModalOverlay from '../pages/ModalOverlay.vue';
import { getMaps } from '../loader/loader';

export default defineComponent({
    setup() {
      var action = ref(false);
      //if (store.getters.google) location.reload();
      let markerOpen = ref(false);
      const state = reactive<UserData>({
        loginStatus: false,
        displayName: "",
        photoURL: "",
        uid: undefined,
      });
      var key = sessionStorage.getItem('key'); 
      if (key) {
        state.uid = key;
      }
      const session = (value: string | undefined) => {
        if(value) sessionStorage.setItem('key', value);
      }
      var userPlayList: any[] = ([]);
      const getDataCol = () => {
        var name = "WWW" + Math.floor((new Date().getTime() + (24 * 1000 * 60 * 60 * 3)) / (24 * 1000 * 60 * 60 * 7))
        var collection = usersRef.doc(state.uid).collection(name).get();
        var getDataCol_google = store.getters.google;
        var getDataCol_map = store.getters.map;
        collection.then((datas) => {
          datas.forEach((data) => {
            const flightPlanCoordinates = [];
            for (let i = 0; i < data.data().lat.length; i++) {
              flightPlanCoordinates.push({ lat: data.data().lat[i], lng: data.data().lng[i] });
            }
            const flightPath = new getDataCol_google.maps.Polyline({
              path: flightPlanCoordinates,
              geodesic: true,
              strokeColor: "#000",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            userPlayList.push(flightPath);
              flightPath.setMap(getDataCol_map);
          });
        })
        .catch((e) => {
          console.log("error",e)
        })
      }
      const allDelete = () => {
        for (const data of userPlayList) {
          data.setMap(null);
        }
      }
      const getUser = async () => {
        var users = usersRef.doc(state.uid).get();
          await users.then((data) => {
            if (!data.exists) {
              data.ref.set({
                screen_name: state.uid,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
      const InGoogle = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        await FirebaseAuth.signInWithPopup(provider)
        .then((result) => {
          state.uid = result.user?.uid;
          state.loginStatus = true;
          state.displayName = result.user?.displayName;
          state.photoURL = result.user?.photoURL;
        })
        .catch((error) => {
          const signinError = `
                    サインエラー
                    エラーメッセージ : ${error.message}
                    エラーコード : ${error.code}
                    `;
          console.log(signinError);
        });
      };
      
      const onSignInGoogle = async () => {
        allDelete();
        await InGoogle();
        await getUser();
        getDataCol();
        session(state.uid);
      }
      const InTwitter = async () => {
        const provider = new firebase.auth.TwitterAuthProvider();
        await FirebaseAuth.signInWithPopup(provider)
        .then((result) => {
          state.uid = result.user?.uid;
          state.loginStatus = true;
          state.displayName = result.user?.displayName;
          state.photoURL = result.user?.photoURL;
        })
        .catch((error) => {
          const signinError = `
                    サインエラー
                    エラーメッセージ : ${error.message}
                    エラーコード : ${error.code}
                    `;
          console.log(signinError);
        });
      };
      const onSignInTwitter = async () => {
        allDelete();
        await InTwitter();
        await getUser();
        getDataCol();
        session(state.uid);
      };
      const InFacebook = async () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        await FirebaseAuth.signInWithPopup(provider)
          .then((result) => {
            state.uid = result.user?.uid;
            state.loginStatus = true;
            state.displayName = result.user?.displayName;
            state.photoURL = result.user?.photoURL;
          })
          .catch((error) => {
            const signinError = `
                      サインエラー
                      エラーメッセージ : ${error.message}
                      エラーコード : ${error.code}
                      `;
            console.log(signinError);
          });
      };
      const onSignInFacebook = async () => {
        allDelete();
        await InFacebook();
        await getUser();
        getDataCol();
        session(state.uid);        
      };
      const onSignOut = () => {
        allDelete();
        userPlayList = [];
        //FirebaseAuth.onAuthStateChanged((user) => {
          //console.log("サインアウト",user)
          FirebaseAuth.signOut()
            .then(() => {
              console.log("ログアウトしました");
              state.loginStatus = false;
              sessionStorage.removeItem('key');
            })
            .catch((error) => {
              console.log(`ログアウト時にエラーが発生しました(${error})`);
            });
        //});
      };
      var stack: any[] = [];
      const dataPop = () => {
        var name = "WWW" + Math.floor((new Date().getTime() + (24 * 1000 * 60 * 60 * 3)) / (24 * 1000 * 60 * 60 * 7));
        if(userPlayList.length > 0) {
          if(state.uid) usersRef.doc(state.uid).collection(name).doc(String(userPlayList.length)).delete();
          var data = userPlayList.pop();
          stack.push(data);
          data.setMap(null);
        }
      }
      const dataPush = () => {
        var name = "WWW" + Math.floor((new Date().getTime() + (24 * 1000 * 60 * 60 * 3)) / (24 * 1000 * 60 * 60 * 7));
        var map = store.getters.map;
        if(stack.length > 0) {
          var position: PositionData = {lat: [], lng: [], expireAt: getExpireAt()};
          var data = stack.pop();
          for(const p of data.getPath().Vc) {
            position.lat.push(p.lat());
            position.lng.push(p.lng());
          }
          userPlayList.push(data);
          data.setMap(map);
          if(state.uid) usersRef.doc(state.uid).collection(name).doc(String(userPlayList.length)).set(position);
        }
      }
      
      onMounted( async() => {   
        await getMaps()//標準東京都
        
        FirebaseAuth.onAuthStateChanged((user) => {
          if (user) {
            state.loginStatus = true;
          } else {
            state.loginStatus = false;
          }
        });
        var name = "WWW" + Math.floor((new Date().getTime() + (24 * 1000 * 60 * 60 * 3)) / (24 * 1000 * 60 * 60 * 7));
        var google = store.getters.google;
        var clickMap = store.getters.map;
        var drawingManager = store.getters.drawingManager;
        var position: PositionData = {lat: [], lng: [], expireAt: null};
        var mapEx = document.getElementById('mapEx') as HTMLElement;
        mapEx.textContent = 'Mapをクリックして描画を開始する';
        let map = document.getElementById("map");
        let marker = document.querySelector('.clickMarker') as HTMLElement;
        
        map?.addEventListener('click', (e) => {
          markerOpen.value = true;
          mapEx.textContent = '始点かアシストマーカーをクリックすると描画完了';
          if(marker) {
            marker.style.left = e.clientX + 'px'; 
            marker.style.top = e.clientY + 'px'; 
          }
        })
    
        google.maps?.event.addListener(drawingManager, 'polylinecomplete', async function(polyline: any) {
          let map = document.getElementById("map");
          function getText(e: any) {
            markerOpen.value = false;
            mapEx.textContent = '描画完了';
            map?.removeEventListener('click', getText)
            //map?.removeEventListener('dblclick', getText)
          }
          map?.addEventListener('click', getText);
          map?.addEventListener('dblclick', getText);
          var polylinePath = polyline.getPath();
          var size = 0;
          if (state.uid) {
            await usersRef.doc(state.uid).collection(name).get().then((docs) => {
              size = docs.size;
            })
            .catch((e) => {
              console.log("error",e)
            })
            for (const data of (Object.entries(polylinePath))[0][1] as any) {
              position.lat.push(data.lat());
              position.lng.push(data.lng());
              position.expireAt = getExpireAt();
            }
            usersRef.doc(state.uid).collection(name).doc(String(size + 1)).set(position);
          } else {
            console.log("ドキュメントなし");
          }
          userPlayList.push(polyline);
          position = { lat: [], lng: [], expireAt: null};
        });
        
        if (store.getters.google) getDataCol();
        
        if (FirebaseAuth) {
          FirebaseAuth.onAuthStateChanged((user) => {
            if (user !== null) {
              state.displayName = user.displayName;
              state.photoURL = user.photoURL;
            }
          });
        }
      });
      onUnmounted(() => {
        if(state.loginStatus) onSignOut();
      })
      return { usersRef, onSignInGoogle, onSignInTwitter, 
               onSignOut, ...toRefs(state), 
               dataPop, dataPush, onSignInFacebook,
               markerOpen, action,
             }          
    },
    components: {
      Agreement,
      ModalOverlay,
    }
    
})
