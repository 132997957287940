
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: "NewsListContents",
    props: {
        contents: {
            type: String,
            default: "",
        },
        featured_media: {
            type: Number,
            default: 0,
        }
    },
    setup(props) {
        var divLength = ref<boolean>(false);
        var content = props.contents.match(/<p>.*?<\/p>/);
        if (props.featured_media === 0) divLength.value = true;
        return { content, divLength }
    },
})
