
import { defineComponent, onMounted, ref, reactive, toRefs, computed, onUnmounted } from 'vue';
import { getDataDateTime } from '@/store/app.utils';
import { commentsRef, usersRef } from '@/firebase/users';
import { FirebaseAuth } from "@/firebase/auth";
import firebase from "firebase/app";
import { UserData, PositionData } from "@/store/app.config";

export default defineComponent({
    name: "BulletionBoard",
    props: {
      title: {
        type: String,
      },
    },
    setup(props) {
      var message = ref<string>("");
      var action = ref(false);
      let markerOpen = ref(false);
      const state = reactive<UserData>({
        loginStatus: false,
        displayName: "",
        photoURL: "",
        uid: undefined,
      });
      let buff = ref<Record<string, string|undefined|null>[]>([]);
      const db = commentsRef.doc(props.title).collection("userComments");
      db.orderBy("created_at", "desc").get()
      .then((docs) => {
        docs.forEach((doc) => {
          buff.value.push(Object.assign(doc.data()));
        });
      });
      const cancel = () => {
        message.value = "";
      }  
      const postComments = () => {
        var comments = message.value;
        const nowTime = computed((dateStr) => {
          return getDataDateTime(dateStr);
        });
        buff.value.unshift({
          screen_img: state.photoURL,
          screen_name: state.displayName,
          comments: message.value,
          date: nowTime.value,
        });
        const db = commentsRef.doc(props.title).collection("userComments");
        db.doc(state.uid).get()
        .then((data) => {
          db.add({
            screen_img: state.photoURL,
            screen_name: state.displayName,
            comments: comments,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            date: nowTime.value,
          })
        }) 
      }
      var key = sessionStorage.getItem('key'); 
      if (key) {
        state.uid = key;
      }
      const session = (value: string | undefined) => {
        if(value) sessionStorage.setItem('key', value);
      }
      
      const InGoogle = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        await FirebaseAuth.signInWithPopup(provider)
        .then((result) => {
          state.uid = result.user?.uid;
          state.loginStatus = true;
          state.displayName = result.user?.displayName;
          state.photoURL = result.user?.photoURL;
        })
        .catch((error) => {
          const signinError = `
                    サインエラー
                    エラーメッセージ : ${error.message}
                    エラーコード : ${error.code}
                    `;
          console.log(signinError);
        });
      };
      
      const onSignInGoogle = async () => {
        await InGoogle();
        session(state.uid);
      }
      const InTwitter = async () => {
        const provider = new firebase.auth.TwitterAuthProvider();
        await FirebaseAuth.signInWithPopup(provider)
        .then((result) => {
          state.uid = result.user?.uid;
          state.loginStatus = true;
          state.displayName = result.user?.displayName;
          state.photoURL = result.user?.photoURL;
        })
        .catch((error) => {
          const signinError = `
                    サインエラー
                    エラーメッセージ : ${error.message}
                    エラーコード : ${error.code}
                    `;
          console.log(signinError);
        });
      };
      const onSignInTwitter = async () => {
        await InTwitter();
        session(state.uid);
      };
      const InFacebook = async () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        await FirebaseAuth.signInWithPopup(provider)
          .then((result) => {
            state.uid = result.user?.uid;
            state.loginStatus = true;
            state.displayName = result.user?.displayName;
            state.photoURL = result.user?.photoURL;
          })
          .catch((error) => {
            const signinError = `
                      サインエラー
                      エラーメッセージ : ${error.message}
                      エラーコード : ${error.code}
                      `;
            console.log(signinError);
          });
      };
      const onSignInFacebook = async () => {
        await InFacebook();
        session(state.uid);        
      };
      const onSignOut = () => {
        //FirebaseAuth.onAuthStateChanged((user) => {
          //console.log("サインアウト",user)
          FirebaseAuth.signOut()
            .then(() => {
              console.log("ログアウトしました");
              state.loginStatus = false;
              sessionStorage.removeItem('key');
            })
            .catch((error) => {
              console.log(`ログアウト時にエラーが発生しました(${error})`);
            });
        //});
      };
      
      onMounted( async() => {   
        FirebaseAuth.onAuthStateChanged((user) => {
          if (user) {
            state.loginStatus = true;
          } else {
            state.loginStatus = false;
          }
        });
      });
      onUnmounted(() => {
        if(state.loginStatus) onSignOut();
      });
      return { usersRef, onSignInGoogle, onSignInTwitter, 
               onSignOut, ...toRefs(state), 
               onSignInFacebook,
               markerOpen, action,
               postComments, buff, message, cancel,
             }          
    }
    
})
