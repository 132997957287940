import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ed03067"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Tool__Item" }
const _hoisted_2 = { href: "https://dqwstepsaday.com/majan/" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageBreadcrumbs = _resolveComponent("PageBreadcrumbs")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageBreadcrumbs),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, [
        _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.majan.title), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.majan.contents, (contents) => {
        return (_openBlock(), _createElementBlock("li", { key: contents }, _toDisplayString(contents), 1))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toolList, (data) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "Tool__Item",
        key: data.title
      }, [
        _createElementVNode("h2", null, [
          _createVNode(_component_router_link, { to: "/playcoinmap" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(data.title), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.contents, (contents) => {
          return (_openBlock(), _createElementBlock("li", { key: contents }, _toDisplayString(contents), 1))
        }), 128))
      ]))
    }), 128)),
    _createVNode(_component_router_view)
  ], 64))
}