import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cecba92e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Modal__Date" }
const _hoisted_2 = { class: "Post" }
const _hoisted_3 = { class: "Modi" }
const _hoisted_4 = { class: "Modal" }
const _hoisted_5 = { class: "Modal__main" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "ButtonOnImage" }
const _hoisted_8 = {
  key: 0,
  class: "Carousel__controller"
}
const _hoisted_9 = { class: "Modal__sub" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalOverlay = _resolveComponent("ModalOverlay")!
  const _component_spin_loding = _resolveComponent("spin-loding")!
  const _component_Infomation = _resolveComponent("Infomation")!
  const _component_PageBreadcrumbs = _resolveComponent("PageBreadcrumbs")!
  const _component_DetailOfHeart = _resolveComponent("DetailOfHeart")!
  const _component_arrow_left = _resolveComponent("arrow-left")!
  const _component_arrow_right = _resolveComponent("arrow-right")!
  const _component_PageCarouselItem = _resolveComponent("PageCarouselItem")!
  const _component_BulletionBoard = _resolveComponent("BulletionBoard")!
  const _component_ProfileData = _resolveComponent("ProfileData")!
  const _component_RecentNews = _resolveComponent("RecentNews")!
  const _component_RelatedNews = _resolveComponent("RelatedNews")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.nowLoad)
      ? (_openBlock(), _createBlock(_component_ModalOverlay, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.nowLoad)
      ? (_openBlock(), _createBlock(_component_spin_loding, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.nowLoad)
      ? (_openBlock(), _createBlock(_component_Infomation, { key: 2 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, "投稿日:" + _toDisplayString(_ctx.newsData.date_gmt.split("T")[0]), 1),
      _createElementVNode("div", _hoisted_3, "更新日:" + _toDisplayString(_ctx.newsData.modified.split("T")[0]), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_PageBreadcrumbs),
        (_ctx.blist)
          ? (_openBlock(), _createBlock(_component_DetailOfHeart, {
              key: 0,
              data: _ctx.bdata,
              onChangeModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.blist=false))
            }, null, 8, ["data"]))
          : _createCommentVNode("", true),
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentList, (data, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "Modal__Items",
            key: data[0]
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentList[index], (content) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "Modal__Content",
                key: content,
                innerHTML: content
              }, null, 8, _hoisted_6))
            }), 128)),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.srcList[index])
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_ctx.prevButton[index])
                      ? (_openBlock(), _createBlock(_component_arrow_left, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.onClickPrev(index))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.nextButton[index])
                      ? (_openBlock(), _createBlock(_component_arrow_right, {
                          key: 1,
                          onClick: ($event: any) => (_ctx.onClickNext(index))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.srcList[index])
                ? (_openBlock(), _createElementBlock("ul", {
                    key: 1,
                    class: "Modal__Image",
                    ref_for: true,
                    ref: "carouselRef"
                  }, [
                    _createVNode(_component_PageCarouselItem, {
                      innerHTML: _ctx.srcList[index][_ctx.nowCarouselNum]
                    }, null, 8, ["innerHTML"]),
                    (_ctx.imgDubble)
                      ? (_openBlock(), _createBlock(_component_PageCarouselItem, {
                          key: 0,
                          innerHTML: _ctx.srcList[index][_ctx.nowCarouselNum + 1]
                        }, null, 8, ["innerHTML"]))
                      : _createCommentVNode("", true)
                  ], 512))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128)),
        _createVNode(_component_BulletionBoard, { title: _ctx.title }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_ProfileData),
        _createVNode(_component_RecentNews),
        _createVNode(_component_RelatedNews, { connection: _ctx.connection }, null, 8, ["connection"])
      ])
    ])
  ], 64))
}