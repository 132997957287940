
import { defineComponent, computed } from "vue";
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import AppHero from "./components/AppHero.vue";

import store from "@/store";
import router from "./router";
import { Page } from "./router";

// import { credentials } from "./firebase/credentials";
// import firebase from "firebase/app";
export default defineComponent({
  setup() {
    // onMounted(() => {
    //   Promise.all([import("./firebase/mess"), import("./firebase/users")]).then((promises) => {
    //     const { messaging } = promises[0];
    //     const { usersRef } = promises[1];
    //     notice(messaging, usersRef);
    //   });
      
    //   const notice = (messaging: firebase.messaging.Messaging, usersRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>) => {
    //     messaging
    //       .getToken({
    //         vapidKey: credentials.messagingConfig.vapidKey,
    //       })
    //       .then((token: string) => {
    //         if (token) {
    //           usersRef
    //             .where("token", "==", token)
    //             .get()
    //             .then((oldLog) => {
    //               if (oldLog.empty) {
    //                 usersRef.add({
    //                   token: token,
    //                   subscribe: true,
    //                 });
    //                 console.log("通知を設定しました。");
    //               } else {
    //                 oldLog.forEach((doc) => {
    //                   usersRef.doc(doc.id).update({
    //                     subscribe: true,
    //                   });
    //                 });
    //                 console.log("通知はすでに登録されています。");
    //               }
    //             });
    //         } else {
    //           console.log("通知の許可をしていません。「通知を許可する」を押してください。");
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("トークンの取得に失敗しました(" + err + ")。");
    //       });
    //   };
    // });
    const nowPage = computed(() => store.getters.nowPage);
    const isTop = computed(() => router.currentRoute.value.name === Page.TOP);

    return {
             nowPage, isTop
           };
  },
  components: {
    AppHeader,
    AppFooter,
    AppHero,
  },
});
