import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75898d7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "infoDisplay" }
const _hoisted_2 = { class: "infoLoading" }
const _hoisted_3 = { class: "Carousel__controller" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left = _resolveComponent("arrow-left")!
  const _component_arrow_right = _resolveComponent("arrow-right")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.infoList[_ctx.nowCarouselNum].title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoList[_ctx.nowCarouselNum].contents, (content) => {
        return (_openBlock(), _createElementBlock("li", { key: content }, _toDisplayString(content), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_arrow_left, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickPrev()))
      }),
      _createVNode(_component_arrow_right, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickNext()))
      })
    ])
  ]))
}