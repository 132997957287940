
import { defineComponent, ref, SetupContext } from "@vue/runtime-core";
import { NewsData, refDate } from "@/store/app.config";
import axios, { AxiosResponse, AxiosError } from "axios";
import { AppData } from "@/store/app.data";

export default defineComponent({
  name: "Date",
  emits: ["change-data"],
  setup(_, context: SetupContext) {
    const newsData = ref<NewsData[]>([]);
    const refDate = ref<number[]>([]);
    let DateList = ref<refDate>({});
    getDate();

    async function getDate() {
      await axios 
      .get(AppData.refDate)
      .then((res: AxiosResponse<NewsData[]>) => {
        for (const data of res.data[0].date.split("-")) {
          refDate.value.push(Number(data));
        }
      })
      .catch((e: AxiosError<{ error: string }>) => {
        console.log(e);
      });

      const date = new Date();
      for (let j = refDate.value[0]; j < date.getFullYear() + 1; j++) {
        DateList.value[j] = [];
        if (j === refDate.value[0]) {
          //最初の月は描き始めた時から
          for (let i = Number(refDate.value[1]); i <= 12; i++) {
            DateList.value[j].push(i);
          }
        } else {
          for (let i = 1; i <= date.getMonth() + 1; i++) {
            DateList.value[j].push(i);
          }
        }
      }
    }
    
    const dateClick = (key: number, data: number) => {
      let params: {before: string, after: string};
      if (data === 12) {
        params = {
          before: `${String(key)}-${("00" + String(data)).slice(-2)}-31T11:59:59`,
          after: `${String(key)}-${("00" + String(data)).slice(-2)}-01T00:00:00`,
        };
      } else {
        params = {
          before: `${String(key)}-${("00" + String(data + 1)).slice(-2)}-01T00:00:00`,
          after: `${String(key)}-${("00" + String(data)).slice(-2)}-01T00:00:00`,
        };
      }
      axios
        .get(AppData.newsPath, { params })
        .then((res: AxiosResponse<NewsData[]>) => {
          newsData.value.length = 0;
          for (const data of res.data) {
            newsData.value.push(data);
          }
          sessionStorage.setItem("NewsData", JSON.stringify(newsData.value)); 
          context.emit("change-data", newsData.value);
        })
        .catch((e: AxiosError<{ error: string }>) => {
          console.log(e);
        });
    };
    
    return { dateClick, DateList };
  },
});
