
import { defineComponent, ref } from 'vue';
import useWindowSize from '@/composable/use-window-size';

export default defineComponent({
    setup() {
      var width = ref<number>(380)
      const setPos = (dw: number) => {
        if (dw <= 428) {
          width.value = 380;
        } else {
          width.value = 600;
        }
      };
      const { dw } = useWindowSize(() => {
        setPos(dw.value);
      });
      setPos(dw.value);
      return { width }
    },
})
