import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a6101ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PagiNation" }
const _hoisted_2 = { class: "PagiNation__List" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dequeBox, (i) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "PagiNation__Item",
          key: i
        }, [
          _createElementVNode("button", {
            class: _normalizeClass({'-on': _ctx.select[i-1]}),
            type: "button",
            onClick: ($event: any) => (_ctx.clickDeque(i))
          }, [
            _createElementVNode("span", null, _toDisplayString(i), 1)
          ], 10, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}