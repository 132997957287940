
import { defineComponent, PropType, ref } from 'vue';
import PolyGraph from './PolyGraph.vue';

export default defineComponent({
    components: { PolyGraph },
    name: "Detail",
    props: {  
        data: {
          type: Object as PropType<any>,
          required: true,
        },
    },
    setup(props) {
      let color = ref<string>(props.data["色"]);
      let species: string[],
      attribMagic: string[],
      attribPhysics: string[],
      attribDamage: string[],
      Pvalue: string[],
      Recovery: string[],
      Abresist: string[],
      Spresist: string[],
      Chresist: string[],
      Raresist: string[],
      Dmresist: string[],
      Atresist: string[],
      skills: string[],
      effects: string[]

      species = props.data["系統ダメージ"].split("、");
      attribMagic = props.data["属性じゅもん"].split("、");
      attribPhysics = props.data["属性斬撃体技"].split("、");
      attribDamage = props.data["属性ダメージ"].split("、");
      Pvalue = props.data["確率"].split("、");
      Recovery = props.data["回復"].split("、");
      Abresist = props.data["異常耐性"].split("、");
      Spresist = props.data["特殊耐性"].split("、");
      Chresist = props.data["変化耐性"].split("、");
      Raresist = props.data["系統耐性"].split("、");
      Dmresist = props.data["ダメージ耐性"].split("、");
      Atresist = props.data["属性耐性"].split("、");
      skills = props.data["スキル"].split("、");
      effects = props.data["特殊効果"].split("、");

      return { species, attribMagic, attribPhysics, attribDamage,
               Pvalue, Recovery, skills, effects, color,
               Abresist, Spresist, Chresist, Raresist, Dmresist, Atresist, 
             }
    },
    methods: {
      imgSrc() {
          return require("../../assets/no_imag.jpg");
      }
    },
})
