import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_overlay = _resolveComponent("modal-overlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "SortButton",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSortUi()))
    }, _toDisplayString(_ctx.$props.buttonName), 1),
    (_ctx.openSort)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["title", {'-fadeUp': _ctx.fadeUp}])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          _createElementVNode("button", {
            class: "back",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeSortUi()))
          }, "閉じる")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.openSort)
      ? (_openBlock(), _createBlock(_component_modal_overlay, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}