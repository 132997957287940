import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e250e69"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "News__list" }
const _hoisted_2 = { class: "News__itemDate" }
const _hoisted_3 = { class: "News__HeartRoad" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "News__update" }
const _hoisted_6 = { class: "News__itemDate" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "News__update" }
const _hoisted_9 = { class: "News__itemDate" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "News__update" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.heroDataList[0].headingHtml), 1),
    _createElementVNode("li", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobList, (job, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: job,
          class: "News__itemTitle",
          onClick: ($event: any) => (_ctx.GoToHeart(index))
        }, [
          _createElementVNode("span", null, _toDisplayString(job) + "のこころ道まとめ", 1)
        ], 8, _hoisted_4))
      }), 128)),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.heroDataList[0].descHtml), 1)
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.heroDataList[1].headingHtml), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.heroDataList[1].child, (data) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "News__HeartRoad",
        key: data.page
      }, [
        _createElementVNode("button", {
          class: "News__itemTitle",
          onClick: ($event: any) => (_ctx.HeartDetail(data.page))
        }, [
          _createElementVNode("span", null, _toDisplayString(data.headingHtml), 1)
        ], 8, _hoisted_7),
        _createElementVNode("div", _hoisted_8, _toDisplayString(data.descHtml), 1)
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.heroDataList[2].headingHtml), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.heroDataList[2].child, (data) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "News__HeartRoad",
        key: data.page
      }, [
        _createElementVNode("button", {
          class: "News__itemTitle",
          onClick: ($event: any) => (_ctx.HeartDetail(data.page))
        }, [
          _createElementVNode("span", null, _toDisplayString(data.headingHtml), 1)
        ], 8, _hoisted_10),
        _createElementVNode("div", _hoisted_11, _toDisplayString(data.descHtml), 1)
      ]))
    }), 128))
  ]))
}