
import store from '@/store';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { colorSort } from '@/store/app.utils';
import { getApi, sortImgDB, binarySearch } from '@/store/app.utils';
import { imgData } from '@/store/app.config';
import DetailOfHeart from '@/components/pages/DetailOfHeart.vue';
import TopButton from '@/components/button/TopButton.vue';
import PageBreadcrumbs from '@/components/pages/PageBreadcrumbs.vue';
import ModalOverlay from '@/components/pages/ModalOverlay.vue';
import Infomation from '@/components/loading/Infomation.vue';
import useWindowSize from "@/composable/use-window-size";
import Indicator from '@/components/loading/Indicator.vue';

export default defineComponent({
  
    setup() {
      let dqwdb: Record<string, string>[];
      let nowLoad = ref(true);
      let readyDB = ref(false);
      let monsterName = ref("");
      async function createDB () {
        await getApi();
        nowLoad.value = false;
        readyDB.value = true;
      }
      createDB();
      const state = reactive<Record<string, number>>({
        "最大HP":0,
        "最大MP":0,
        "ちから":0,
        "みのまもり":0,
        "攻撃魔力":0,
        "回復魔力":0,
        "すばやさ":0,
        "きようさ":0,
      })
      function init(index: number) {
        switch (index) {
          case 0:
            state["最大HP"] = 0;
            break;
          case 1:
            state["最大MP"] = 0;
            break;
          case 2:
            state["ちから"] = 0;
            break;
          case 3:
            state["みのまもり"] = 0;
            break;
          case 4:
            state["攻撃魔力"] = 0;
            break;
          case 5:
            state["回復魔力"] = 0;
            break;
          case 6:
            state["すばやさ"] = 0;
            break;
          case 7:
            state["きようさ"] = 0;
            break;
        }
      }
      const onOpenMenu = ref<boolean>(false);
      const openMenu = () => {
        onOpenMenu.value = true;
      }
      const disables = ref<boolean[]>(Array(Object.keys(toRefs(state)).length).fill(true));
      const colorButton = ["full","red","yellow","blue","purple","green","all"];
      
      const clickColor = (color: string) => {
        resultData.value = color === "all" 
          ? [...refData.value] 
          : colorSort(refData.value, color);
      }
      const resultData  = ref<Record<string, string>[]>([]);
      const refData = ref<Record<string, string>[]>([]);
      const blist = ref<boolean[]>([]);
      const search = () => {
        dqwdb = store.getters.dbCaching;
        const imgList: imgData[] = store.getters.imgDb;
        const sortData = sortImgDB(imgList);
    
        dqwdb.forEach((item) => {
          item.url = binarySearch(sortData, item);
        });
        
        blist.value = [];
        resultData.value = dqwdb.filter((data) => {
          const minStats = ["最大HP", "最大MP", "ちから", "みのまもり", "攻撃魔力", "回復魔力", "すばやさ", "きようさ"];
          for (const stat of minStats) {
            if (Number(data[stat]) < Number(state[stat])) {
              return false;
            }
          }
          blist.value.push(false);
          return true;
        });
        refData.value = [...resultData.value]
      }
      const DataReset = (index: number) => {
        init(index);
        disables.value[index] = !disables.value[index];
      }
      const detail = (index: number) => {
        blist.value[index] = !blist.value[index];
      }
      let wide = ref<boolean>(false);
      const setPos = (dw: number) => {
        if (dw > 768) {
          wide.value = true;
        } else {
          wide.value = false;
        }
      };
      const { dw } = useWindowSize(() => {
        setPos(dw.value);
      });
      setPos(dw.value);
      
      return { onOpenMenu, monsterName, 
               openMenu, colorButton,
               DataReset, 
               ...toRefs(state), state, disables, 
               search, nowLoad, blist, detail, clickColor,
               wide, resultData }
    },
    methods: {
      imgSrc() {
          return require("../assets/no_imag.jpg");
      }
     },
    components: { DetailOfHeart, TopButton, PageBreadcrumbs, ModalOverlay, Infomation, Indicator },
})
