
import { ref, defineComponent, onMounted, onUnmounted, computed } from 'vue';
import { AppData } from '@/store/app.data';
import store from '@/store';
import router from '@/router';
import PageBreadcrumbs from './PageBreadcrumbs.vue';
import BackButton from '../button/BackButton.vue';
import TopButton from '../button/TopButton.vue';
import { quicksort, colorSort } from '@/store/app.utils';
import DetailOfHeart from './DetailOfHeart.vue';

export default defineComponent({
  name: "HeartResult",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const all = computed(() => store.getters.allMatch);
    const three = store.getters.threeMatch;
    var mainThree = ref<Record<string, string>[]>([]);
    mainThree.value = quicksort(three);
    
    const job = AppData.jobList[Number(props.id)];
    const colorButton = ["red","yellow","blue","purple","green","all"];
    const allList =ref<boolean[]>([]);
    const threeList = ref<boolean[]>([]);
    // for (let i = 0; i < all.value.length; i++) {
    //   allList.value.push(false);
    // }
    // for (let i = 0; i < three.length; i++) {
    //   threeList.value.push(false);
    // }
    const allButton = (index: number) => {
      allList.value[index] = !allList.value[index];
    }
    const threeButton = (index: number) => {
      threeList.value[index] = !threeList.value[index];
    }
    const allcolor = () => {
      mainThree.value = quicksort(three);
    }
    const clickcolor = (color: string) => {
      mainThree.value = colorSort(quicksort(three), color);
    }  
    const sortedAll = quicksort(all.value);
    const sortedThree = quicksort(three);
    const changeModal = (index: number) => {
      allList.value[index] = false;
      setTimeout(() => {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      },0);
    }
    const onCloseModal = () => {
      router.go(-1);
    };
    const gotoTop = () => {
      var Element = document.querySelector(".Heart");
      if(Element !== null) Element.scrollTop = 50
    }
    const pageTop = ref(false);
    onMounted(() => {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      function func() {
        let scroll = document.querySelector(".Heart")?.scrollTop;
        if (scroll && scroll >= 500) {
          pageTop.value = true;
        } else if (scroll && scroll < 500) {
          pageTop.value = false;
        }
      } 
    document.querySelector(".Heart")?.addEventListener("scroll", func, {passive: true});
    })
		onUnmounted(() => {
      document.getElementsByTagName("body")[0].style.overflow = "";
    })
    return { sortedThree, sortedAll, onCloseModal, job, changeModal,
             clickcolor, colorButton, gotoTop, pageTop, 
             allList, threeList, allButton, threeButton, mainThree, allcolor,
           }
  },
  methods: {
    imgSrc() {
        return require("../../assets/no_imag.jpg");
    }
  },
  components: {
    PageBreadcrumbs,
    BackButton,
    TopButton,
    DetailOfHeart,
  }
})
