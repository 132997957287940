
import { defineComponent, onMounted, onUnmounted } from 'vue';


export default defineComponent({
    name: "overlay",
	setup() {
		onMounted(() => {
			document.getElementsByTagName("body")[0].style.overflow = "hidden";
		})
		onUnmounted(() => {
			document.getElementsByTagName("body")[0].style.overflow = "";
		})

	},
})
