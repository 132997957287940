<template>
  <span class="Back__Button"></span>
</template>

<style lang="scss" scoped>
.Back__Button {
  cursor: pointer;
  z-index: 1000;
  display: inline-block;
  vertical-align: middle;
  color: #333;
  line-height: 1;
  position: fixed;
  left: 10%;
  width: 2em;
  height: 2em;
  border: 0.1em solid currentColor;
  background: currentColor;
  border-radius: 50%;
  box-sizing: content-box;
}

.Back__Button::before {
  content: '';
  color: #fff;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 0.8em 0.8em;
  border-right-color: currentColor;
  border-left: 0;
  transform: translateX(-15%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
