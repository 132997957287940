import { Credentials, MapConfig } from "./types/credentials";


export const credentials: Credentials = {
  config: {
    apiKey: "AIzaSyDAzQP2Fj3P6c6AwcFtdHPFDlftuTWX5AM",
    authDomain: "sample-app-2d360.firebaseapp.com",
    projectId: "sample-app-2d360",
    storageBucket: "sample-app-2d360.appspot.com",
    messagingSenderId: "632487339418",
    appId: "1:632487339418:web:cc622cccfeb71e18b50623",
  },
  messagingConfig: {
    vapidKey: "BAouytdGeTDPJTXUyoQGPVTnlvbjIDkpRYRiNDUJ0y-Lm0fS_emrThUB5gL-jxE5eLZoai19b-DKlmd3eiTEgPk",
  },
};

export const CredentialsMap: MapConfig = {
  config: { 
      apiKey: "AIzaSyC5ih-9xcEHzG88AHbNXXPE3-GNmooe9cM",
      version: "weekly",
      libraries: ["drawing","geometry"]
  },
}