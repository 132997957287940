import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79390cd3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Search__result" }
const _hoisted_2 = { class: "Search" }
const _hoisted_3 = { class: "Search__Sub" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalOverlay = _resolveComponent("ModalOverlay")!
  const _component_Infomation = _resolveComponent("Infomation")!
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_NewsTab = _resolveComponent("NewsTab")!
  const _component_SearchArticle = _resolveComponent("SearchArticle")!
  const _component_ProfileData = _resolveComponent("ProfileData")!
  const _component_RecentNews = _resolveComponent("RecentNews")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.nowLoad)
      ? (_openBlock(), _createBlock(_component_ModalOverlay, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.nowLoad)
      ? (_openBlock(), _createBlock(_component_Infomation, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("h1", _hoisted_1, "検索結果：" + _toDisplayString(_ctx.keyword.search), 1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.newsData.length === 0)
        ? (_openBlock(), _createBlock(_component_NotFound, { key: 0 }))
        : (_openBlock(), _createBlock(_component_NewsTab, {
            key: 1,
            class: "Search__Main",
            "news-data": _ctx.newsData,
            newsCategories: _ctx.newsCategories
          }, null, 8, ["news-data", "newsCategories"])),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SearchArticle),
        _createVNode(_component_ProfileData),
        _createVNode(_component_RecentNews)
      ])
    ])
  ], 64))
}