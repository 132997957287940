
import { defineComponent, computed, ref } from 'vue';
import { AppData } from '@/store/app.data';
import { Dictionary, DispatchType, imgData } from '@/store/app.config';
import store from '@/store';
import router from '@/router';
import PageBreadcrumbs from './PageBreadcrumbs.vue';
import ModalOverlay from '../pages/ModalOverlay.vue';
import Infomation from '../loading/Infomation.vue';
import { getApi, sortImgDB, binarySearch } from '@/store/app.utils';
import RecentNews from '@/views/RecentNews.vue';
import ProfileData from '../../views/ProfileData.vue';
import Indicator from '../loading/Indicator.vue';

import RoadTree from './RoadTree.vue';
export default defineComponent({
    name: "HeartRoad",
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      async function createDB() {
        await getApi()
        // dqwdb = store.getters.dbCaching;
        // const imgList: imgData[] = store.getters.imgDb;
        // const sortData = sortImgDB(imgList);
        // for (let i = 0; i < dqwdb.length; i++) {
        //    dqwdb[i]["url"] = binarySort(sortData, dqwdb[i]);
        //  }
        nowLoad.value = false;
      }
      createDB();

      const color = computed(() => AppData.color[Number(props.id)]);
      const status = computed(() => AppData.status[Number(props.id)]);
      const conditions = computed(() => AppData.conditions[Number(props.id)]);
      const job = computed(() => AppData.jobList);
      const jobimg = computed(() => require("../../assets/jobList/job" + String(props.id) + ".jpg"));
      let isOpenn = ref(false);
      let nowLoad = ref(true);
      const isOpenStatus = ref(color.value.map(data => Array(data.length).fill(false)));
      
      const openStatus = (index: number, num: number) => {
        isOpenStatus.value[index][num]= !isOpenStatus.value[index][num];
      }
      
      let jobId = ref(Number(props.id));
      const onClickPagePrev = () => {
        jobId.value = Number(props.id) - 1;
        if (jobId.value < 0) {
          jobId.value = job.value.length - 1;
        }
        router.push({ name: 'RHeartRoadTest', params: { id: jobId.value } });
      };
      const onClickPageNext = () => {
        jobId.value = Number(props.id) + 1;
        if(jobId.value >= job.value.length) {
          jobId.value = 0;
        }
        router.push({ name: 'RHeartRoadTest', params: { id: jobId.value } });
      };
      
      return { color, status, job, jobimg, nowLoad, jobId, 
               onClickPagePrev, onClickPageNext, isOpenn, openStatus, isOpenStatus }
    },
    components: { PageBreadcrumbs, ModalOverlay, Infomation,
       ProfileData, RecentNews,  RoadTree, Indicator
       },
})
